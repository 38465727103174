<app-loading *ngIf="loading"></app-loading>
<app-ui-main-menu 
    *ngIf="!loading"
    [userRegistration]="account"
>
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{ showCanceled ? 'Editar' : 'Completar'}} cadastro</mat-card-title>
          </mat-card-header>
        <mat-card-content>
            <form [formGroup]="fg">
                <div class="row">
                    <div class="col-12 loginForm">
                        <mat-form-field appearance="outline" class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                            <mat-label>Nome completo</mat-label>
                            <input
                                matInput
                                formControlName="name"
                            />
                        </mat-form-field>
    
                        <mat-form-field appearance="outline" class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                            <mat-label>CPF</mat-label>
                            <input
                                matInput
                                formControlName="cpf"
                                [specialCharacters]="['/', '.', '-']"
                                [mask]="'000.000.000-00'"
                            />
                            <mat-error *ngIf="fg.hasError('invalid', ['cpf'])">
                                CPF inválido
                            </mat-error>
                    
                            <mat-error *ngIf="fg.hasError('alreadyExist', ['cpf'])">
                                Usuário já cadastrado
                            </mat-error>
                        </mat-form-field>
        
                        <mat-form-field appearance="outline" class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <mat-label>Data de Nascimento</mat-label>
                            <input
                                matInput
                                type="text"
                                formControlName="birthdate"
                                [specialCharacters]="['/']"
                                [mask]="'00/00/0000'"
                            />
                            <mat-error *ngIf="this.fg.controls.birthdate.invalid">Data inválida</mat-error>
                        </mat-form-field>
    
                        <mat-form-field appearance="outline" class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <mat-label>Celular</mat-label>
                            <input
                                matInput
                                formControlName="mobile"
                                [specialCharacters]="['-', '(', ')', ' ']"
                                [mask]="'(00) 00000-0000'"
                            />
                        </mat-form-field>
                    
                        <mat-form-field appearance="outline" class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <mat-label>E-mail</mat-label>
                            <input
                                matInput
                                formControlName="email"
                                (change)="onChangeEmail()"
                            />
                            <mat-error *ngIf="fg.hasError('invalid', ['email'])">
                                E-mail inválido
                            </mat-error>
                        </mat-form-field>
                        
                        <mat-form-field appearance="outline" class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <mat-label>Função</mat-label>
                            <mat-select
                                formControlName="function"
                                required
                            >
                                <mat-option *ngFor="let function of functions" [value]="function">
                                {{ function }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field *ngIf="!showCanceled" appearance="outline" class="col-xl-6 col-lg-6 col-md-6 col-sm-12 roundedField">
                            <mat-label>Senha</mat-label>
                            <input
                              matInput
                              [type]="typeSenha"
                              appNoAutoComplete
                              formControlName="password"
                              placeholder="Senha"
                              (input)="onChangePassword()"
                              required
                            />
                            <span class="eye" matSuffix>
                              <fa-icon
                                icon="eye"
                                *ngIf="typeSenha === 'password'"
                                (click)="typeSenha = 'text'"
                              ></fa-icon>
                              <fa-icon
                                icon="eye-slash"
                                *ngIf="typeSenha === 'text'"
                                (click)="typeSenha = 'password'"
                              ></fa-icon>
                            </span>
                            <span matSuffix> </span>
                            <mat-error *ngIf="fg.hasError('required', ['password'])">
                              <strong>Por favor digite uma senha</strong>
                            </mat-error>
                            <mat-hint
                              *ngIf="
                                passStrength.visivel &&
                                passStrength.iniciado &&
                                fg.controls['password'].value
                              "
                            >
                              {{ passStrength.dica }}
                              <mat-progress-bar
                                *ngIf="passStrength.visivel && passStrength.iniciado"
                                [value]="passStrength.valor"
                                [color]="passStrength.cor"
                              >
                              </mat-progress-bar>
                            </mat-hint>
                            <div
                                class="tips-box"
                                *ngIf="
                                passStrength.visivelDicas &&
                                passStrength.iniciado &&
                                fg.controls['password'].value
                                "
                            >
                                <br />
                                <div align="left">{{ passStrength.titulo }}</div>
                                <br />
                
                                <span class="tips" [innerHTML]="tips"></span>
                            </div>
                        </mat-form-field>

                        <mat-form-field *ngIf="!showCanceled" appearance="outline" class="roundedField col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <mat-label>Confirmação de senha</mat-label>
                            <input
                                matInput
                                [type]="typeSenha"
                                appNoAutoComplete
                                formControlName="repassword"
                                placeholder="Confirmação de senha"
                                required
                                (change)="onChangeRepassword()"
                                (input)="onChangeRepassword()"
                            />
                            <span class="eye" matSuffix>
                                <fa-icon
                                icon="eye"
                                *ngIf="typeSenha === 'password'"
                                (click)="typeSenha = 'text'"
                                ></fa-icon>
                                <fa-icon
                                icon="eye-slash"
                                *ngIf="typeSenha === 'text'"
                                (click)="typeSenha = 'password'"
                                ></fa-icon>
                            </span>
                            <span matSuffix> </span>
                            <mat-error *ngIf="fg.hasError('notSame', ['repassword'])">
                                <strong>Senhas não coincidem! Por favor verifique.</strong>
                            </mat-error>
                            <mat-error *ngIf="fg.hasError('required', ['repassword'])">
                                <strong>Por favor confirme sua senha!</strong>
                            </mat-error>
                        </mat-form-field>
                        
                    </div>
                </div>
                <div class="actionBtn">
                    <button *ngIf="showCanceled" class="btn-cancel" mat-button (click)="cancel()">Cancelar</button>
                    <button class="btn-green" type="submit" mat-button (click)="save()">Salvar</button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</app-ui-main-menu>



