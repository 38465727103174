<app-loading *ngIf="loading"></app-loading>
<ng-container *ngIf="!loading">
    <h2 mat-dialog-title>{{title}}</h2>
    <mat-dialog-content>
        <form [formGroup]="fg">
            <div class="row">
                <div class="col-12 loginForm">
                    <div class="col-12">
                        <mat-form-field appearance="outline" class="col-6">
                            <mat-label>CPF</mat-label>
                            <input
                                matInput
                                formControlName="cpf"
                                [specialCharacters]="['/', '.', '-']"
                                [mask]="'000.000.000-00'"
                            />
                            <mat-error *ngIf="fg.hasError('invalid', ['cpf'])">
                                CPF inválido
                            </mat-error>
                    
                            <mat-error *ngIf="fg.hasError('alreadyExist', ['cpf'])">
                                Usuário já cadastrado
                            </mat-error>
                        </mat-form-field>
                        <button 
                            class="btn-primary" 
                            type="button" 
                            mat-button 
                            (click)="onChangeCPF()"
                        >
                            <mat-spinner *ngIf="checkingCPF" [diameter]="30" color="primary"></mat-spinner>
                            <span *ngIf="!checkingCPF && this.fg.controls.cpf.status !== 'DISABLED'">Validar</span>
                        </button>
                    </div>
    
                    <mat-form-field appearance="outline" class="col-12">
                        <mat-label>Nome completo</mat-label>
                        <input
                          matInput
                          formControlName="name"
                        />
                      </mat-form-field>
    
                      <mat-form-field appearance="outline" class="col-4">
                        <mat-label>Data de Nascimento</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="birthdate"
                          [specialCharacters]="['/']"
                          [mask]="'00/00/0000'"
                        />
                        <mat-error *ngIf="this.fg.controls.birthdate.invalid">Data inválida</mat-error>
                      </mat-form-field>
    
                      <mat-form-field appearance="outline" class="col-4">
                        <mat-label>Celular</mat-label>
                        <input
                          matInput
                          formControlName="mobile"
                          [specialCharacters]="['-', '(', ')', ' ']"
                          [mask]="'(00) 00000-0000'"
                        />
                      </mat-form-field>
                  
                      <mat-form-field appearance="outline" class="col-4">
                        <mat-label>E-mail</mat-label>
                        <input
                          matInput
                          formControlName="email"
                          (change)="onChangeEmail()"
                        />
                        <mat-error *ngIf="fg.hasError('invalid', ['email'])">
                          E-mail inválido
                        </mat-error>
                      </mat-form-field>
                      <mat-form-field appearance="outline" class="col-4">
                        <mat-label>Função</mat-label>
                        <mat-select
                          formControlName="function"
                          required
                        >
                          <mat-option *ngFor="let function of functions" [value]="function">
                            {{ function }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                  
                </div>
            </div>
            <div class="actionBtn">
                <button class="btn-cancel" type="button" mat-button mat-dialog-close>Cancelar</button>
                <button class="btn-green" type="submit" mat-button (click)="save()">Salvar</button>
            </div>
        </form>
    </mat-dialog-content>
</ng-container>

