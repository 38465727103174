<div class="centerDiv">
  <mat-card class="content">
    <p align="center">
      <img src="assets/logoRegister.png" class="imgLogo"/>
    </p>

    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="outline">
        <mat-label>CPF</mat-label>
        <input
          matInput
          formControlName="cpf"
          [specialCharacters]="['/', '.', '-']"
          [mask]="'000.000.000-00'"
          (change)="onChangeCPF()"
          required
        />
        <mat-error *ngIf="form.hasError('invalid', ['cpf'])">
          CPF inválido
        </mat-error>

        <mat-error *ngIf="form.hasError('alreadyExist', ['cpf'])">
          Usuário já cadastrado
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Nome completo</mat-label>
        <input
          matInput
          formControlName="name"
          required
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>E-mail</mat-label>
        <input
          matInput
          formControlName="email"
          (change)="onChangeEmail()"
          required
        />
        <mat-error *ngIf="form.hasError('invalid', ['email'])">
          E-mail inválido
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Celular</mat-label>
        <input
          matInput
          formControlName="mobile"
          [specialCharacters]="['-', '(', ')', ' ']"
          [mask]="'(00) 00000-0000'"
          required
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Senha</mat-label>
        <input
          matInput
          [type]="typePassword"
          formControlName="password"
          (input)="onChangePassword()"
          required
        />
        <span class="eye" matSuffix>
          <fa-icon
            icon="eye"
            *ngIf="typePassword === 'password'"
            (click)="typePassword = 'text'"
          ></fa-icon>
          <fa-icon
            icon="eye-slash"
            *ngIf="typePassword === 'text'"
            (click)="typePassword = 'password'"
          ></fa-icon>
        </span>
        <span matSuffix> </span>

        <mat-error
          *ngIf="form.hasError('invalid', ['password'])">
          Senha inválida:
          <div *ngFor="let tips of this.passStrength.dicas">
            {{tips}}
          </div>
        </mat-error>

        <mat-hint
          *ngIf="
            passStrength.visivel &&
            passStrength.iniciado &&
            form.controls['password'].value
          "
        >
          {{ passStrength.dica }}
          <mat-progress-bar
            *ngIf="passStrength.visivel && passStrength.iniciado"
            [value]="passStrength.valor"
            [color]="passStrength.cor"
          >
          </mat-progress-bar>
        </mat-hint>
      </mat-form-field>

      <p *ngIf="passStrength.visivel &&
      passStrength.iniciado"

      [ngStyle]="{'height': (passStrength.dicas.length*5)+'px'}"></p>

      <div>
        <mat-checkbox formControlName="term" required>
        </mat-checkbox>

        <span>
          Concordo com os
          <a href="https://previdas.com.br/termos-de-uso/" target="_blank">
            Termos de uso e política de privacidade
          </a>
        </span>
      </div>

      <mat-error
        *ngIf="!form.controls['term'].valid && form.controls['term'].touched"
      >
        Por favor, para continuar você primeiro precisa aceitar os termos de
        contratação e uso, e a política de privacidade.
      </mat-error>

      <p align="center">
        <button
          mat-button
          class="btn-primary btn-large"
          [disabled]="loading"
          type="submit"
        >
          <span *ngIf="!loading; else loadingSpiner">Cadastrar</span>

          <ng-template #loadingSpiner>
            <mat-spinner [diameter]="30" color="primary"> </mat-spinner>
          </ng-template>
        </button>
      </p>
    </form>
  </mat-card>
</div>
