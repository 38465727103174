import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FunctionsType } from '../../login/user.interface';
import { LoginService } from 'src/app/services/login.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id: number | 'novo' },
    private snack: MatSnackBar,
    private loginService: LoginService,
    private usersService: UsersService
  ) { }

  title = 'Cadastrar usuário';

  fg = new UntypedFormGroup({
    id: new UntypedFormControl(null),
    cpf: new UntypedFormControl(null,[Validators.required]),
    name: new UntypedFormControl(null,[Validators.required]),
    birthdate: new UntypedFormControl(null,[Validators.required]),
    mobile: new UntypedFormControl(null,[Validators.required]),
    email: new UntypedFormControl(null,[Validators.required]),
    function: new UntypedFormControl(null,[Validators.required]),
  });

  functions = ['Administrador', 'Assistente'];

  loading = false;
  checkingCPF = false;

  ngOnInit(): void {
    if(this.data.id !== 'novo'){
      this.title = 'Editar usuário';
      this.load();
    }else{
      this.fg.disable();
      this.fg.controls.cpf.enable();
    }
  }

  load(){
    this.loading = true;
    this.usersService.getUserById(Number(this.data.id)).subscribe(
      res=>{
        this.onApplyData(res.data);
      },
      error=>{
        this.loading = false;
        this.loginService.showMessage('Erro ao buscar usuário', 3000);
        this.dialogRef.close()
      }
    )
  }

  onApplyData(user){
    this.fg.patchValue({
      id: user.id,
      cpf: user.cpf,
      name: user.name,
      birthdate: user.birthdate,
      mobile: user.mobile,
      email: user.email,
      function: user.function,
    });

    this.fg.controls.cpf.disable();
    this.loading = false;
  }

  async onChangeCPF(){
    this.checkingCPF = true;
    this.fg.controls.cpf.disable()
    if(!this.loginService.isCpfPatternValid(this.fg.controls.cpf.value)) {
      this.fg.controls.cpf.setErrors({
        invalid: true
      })
      this.checkingCPF = false;
      this.fg.controls.cpf.enable();
      return;
    }

    if(await this.usersService.userExist(this.fg.controls.cpf.value).toPromise()) {
      this.fg.controls.cpf.setErrors({
        alreadyExist: true
      })
      this.checkingCPF = false;
      this.fg.controls.cpf.enable();
      return;
    }

    this.loginService.showMessage('CPF válido. Continue com o cadastro do usuário', 4000);
    this.checkingCPF = false;
    this.fg.enable();

  }

  
  public onChangeEmail(){
    if(!this.loginService.isEmailPatternValid(this.fg.controls.email.value)) {
      this.fg.controls.email.setErrors({
        invalid: true
      })
    }
  }

  public save(){
    if(this.fg.invalid){
      this.loginService.showMessage('Preencha os campos corretamente', 3000);
      return;
    }

    this.loading = true;
    this.usersService.createUserInAccount(this.fg.getRawValue()).subscribe(
      res=>{
        this.loginService.showMessage('Usuário salvo com sucesso', 3000);
        this.dialogRef.close({ success: true });
      },
      error =>{
        this.loading = false;
        this.loginService.showMessage('Erro ao salvar dados, tente novamente', 3000);
      }
    )
  }

}
