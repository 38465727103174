import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UiConfirmationDialogComponent } from '../ui-confirmation-dialog/ui-confirmation-dialog.component';

@Component({
  selector: 'app-ui-select-document',
  templateUrl: './ui-select-document.component.html',
  styleUrls: ['./ui-select-document.component.scss']
})
export class UiSelectDocumentComponent {
  constructor(
    public readonly dialogRef: MatDialogRef<UiConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { documents: string[] }
  ) {}

}
