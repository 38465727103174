import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IndicationsService } from 'src/app/services/indications.service';
import { LoginService } from 'src/app/services/login.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-ui-select-patient',
  templateUrl: './ui-select-patient.component.html',
  styleUrls: ['./ui-select-patient.component.scss']
})
export class UiSelectPatientComponent implements OnInit, OnChanges {
  @Input() selectedPatient = null;
  @Output() change: EventEmitter<any> = new EventEmitter();

  public busca: UntypedFormControl = new UntypedFormControl();
  public selected: UntypedFormControl = new UntypedFormControl();
  public _data: any[] = [];

  loading = false;
  exibir = false;
  changeExterno = false;

  transporte: any;

  constructor(
    private patientService: PatientService,
    private indicationService: IndicationsService,
    private loginService: LoginService,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    if(changes.selectedPatient && changes.selectedPatient.currentValue){
      console.log(this.selectedPatient);
      this.busca.setValue(this.selectedPatient.name);
      this.selected.setValue(this.selectedPatient);
      this.busca.disable();
    }
  }

  ngOnInit() {

    this.renderer.listen('document', 'click', (event: any) => {
      const clickedInside = this.elementRef.nativeElement.contains(
        event.target
      );
      if (!clickedInside) {
        // Se o clique foi fora do componente, esconda a lista
        this.exibir = false;
      }
    });

    this.busca.valueChanges
      .subscribe(() => {
        if (!this.changeExterno) {
          this.filtrar();
        }
      });
  }

  protected filtrar() {
    if(!this.busca.value){
      this.exibir = false;
      this.change.emit({id:null});
    }

    if(!!this.busca.value && this.busca.value.length > 0){
      this.loading = true;
      this.patientService.getByName(this.busca.value).subscribe(
        (res) => {
          this._data = res.data;
          this.loading = false;
        },
        error => {
          this._data = [];
          this.loading = false;
        });
    }
  }

  onBlur(i = 0): void {
    setTimeout(() => {
      if (this.exibir)
        this.onBlur(i + 1);

      if (i > 10)
        this.cleanData();

    }, 10);
  }

  cleanData(){
    this.exibir = false;
    this.selected.setValue('');
    this.change.emit(null);
    this.busca.setValue('');
    this._data = [];
  }

  selecionar(patient: any): void {
    if(!patient || !patient?.id)
      return;

    this.loading = true;
    this.indicationService.checkAvailabilityForScheduling(patient.id).subscribe({
      next: available => {
        this.loading = false;

        if (!available) {
          this.busca.setValue('');
          this.loginService.showMessage('O cliente possui um agendamento em andamento.', 6000);
          return;
        }

        this.changeExterno = true;
        this.busca.setValue(patient.name);
        this.selected.setValue(patient);
        this.change.emit(patient);

        this.exibir = false;
        setTimeout(() => {
          this.changeExterno = false;
        }, 1000);

      },
      error: () => {
        this.loading = false;
      }
    });
  }

}
