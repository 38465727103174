import { Injectable } from '@angular/core';
import { ApiServiceService } from './api-service.service';
import { Observable, Subject } from 'rxjs';
import { NewUserAccountRequest } from '../pages/login/user.interface';
import { DefaultResponse } from '../pages/base.interface';
import { User } from '../interfaces/user.interface';
import { ILoginResponse } from '../pages/login/login.interface';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private apiService: ApiServiceService
  ) { }

  private userUpdatedSource = new Subject<void>();

  userUpdated$ = this.userUpdatedSource.asObservable();

  notifyUserUpdated() {
    this.userUpdatedSource.next();
  }

  public userExist(
    cpf: string
  ): Observable<Boolean> {
    return this.apiService.get(
      `/user/userExist/${cpf}`  
    );
  }

  public getUsers(
    status: 'active' | 'pending' | 'inactive'
  ): Observable<DefaultResponse<any>> {
    return this.apiService.get(
      `/user/list?status=${status}`  
    );
  }

  public getUserById(userId: number): Observable<DefaultResponse<any>> {
    return this.apiService.get(
      `/user/${userId}`  
    );
  }

  public getUserByToken(token: string): Observable<DefaultResponse<any>> {
    return this.apiService.get(
      `/user/validation?token=${token}`  
    );
  }

  public newAccount(
    body: NewUserAccountRequest
  ): Observable<Boolean> {
    return this.apiService.post(
      `/user`, body  
    );
  }

  public createUserInAccount(
    body: NewUserAccountRequest
  ): Observable<DefaultResponse<any>> {
    return this.apiService.post(
      `/user/set-in-account`, body  
    );
  }

  public forgotPassword(data:{
    cpf: string,
  }):Observable<DefaultResponse<{email: string}>>{
    return this.apiService.post('/email/forgot-password', {cpf: data.cpf})
  }
  
  public onSendInvitation(userId: number):Observable<DefaultResponse<void>>{
    return this.apiService.post(`/email/invite`, { userId })
  }
  
  public completeUserInvitation(token:string, user: User):Observable<DefaultResponse<ILoginResponse>>{
    return this.apiService.post(`/user/complete?token=${token}`, { user })
  }

  public changePassword(pass:string):Observable<DefaultResponse<void>>{
    return this.apiService.post('/user/change-pass', {pass})
  }

  public onChangeStatus(userId: number):Observable<DefaultResponse<void>>{
    return this.apiService.put(`/user/${userId}/change-status`)
  }
}
