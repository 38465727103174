import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { SignatureService } from 'src/app/services/signature.service';
import { UsersService } from 'src/app/services/users.service';
import { AccountInfo, LocalStorageUtil } from 'src/app/util/local-storage-util';
import { PasswordStrength } from 'src/app/util/password-strength.class';

@Component({
  selector: 'app-complete-registration',
  templateUrl: './complete-registration.component.html',
  styleUrls: ['./complete-registration.component.scss']
})
export class CompleteRegistrationComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private loginService: LoginService,
    private userService: UsersService,
    private signatureService: SignatureService
  ) {
    this.token = this.activatedRoute.snapshot.params['token'];
    this.showCanceled = this.activatedRoute.snapshot.routeConfig.path === "profile/:token";
   }

  token = '';
  loading = false;
  passwordConfirmed = false;
  showCanceled = false;

  account:AccountInfo;

  fg = new UntypedFormGroup({
    id: new UntypedFormControl(null),
    cpf: new UntypedFormControl(null,[Validators.required]),
    name: new UntypedFormControl(null,[Validators.required]),
    birthdate: new UntypedFormControl(null,[Validators.required]),
    mobile: new UntypedFormControl(null,[Validators.required]),
    email: new UntypedFormControl(null,[Validators.required]),
    function: new UntypedFormControl(null,[Validators.required]),
    password: new UntypedFormControl(null,[]),
    repassword: new UntypedFormControl(null,[])
  });

  passStrength = new PasswordStrength();
  tips = '';
  typeSenha = 'password';

  functions = ['Administrador', 'Assistente'];

  ngOnInit(): void {
    this.getInfo();
    
    if(!this.showCanceled){
      this.fg.controls.password.setValidators([Validators.required])
      this.fg.controls.repassword.setValidators([Validators.required])
      this.fg.controls.password.updateValueAndValidity({onlySelf: true})
      this.fg.controls.repassword.updateValueAndValidity({onlySelf: true})
    }
  }

  getInfo(){
    this.loading = true; 
    if(!this.token) {
      this.loginService.showMessage('Link inválido', 3000);
      this.router.navigate([`/login`]);
      return;
    }

    this.userService.getUserByToken(this.token).subscribe(
      res=>{
        if(res.status){
          if(!this.showCanceled)
          this.account = {
            admin: false,
            userName: res.data.name,
            accountName: ' '
          }
          this.onApplyData(res.data);
        }else{
          this.loginService.showMessage('Link inválido', 3000);
          this.router.navigate([`/login`]);
        }
      },
      error=>{
        this.loginService.showMessage('Erro na validação do link', 3000);
        this.router.navigate([`/login`]);
      }
    )
  }

  onApplyData(user){
    this.fg.patchValue({
      id: user.id,
      cpf: user.cpf,
      name: user.name,
      birthdate: user.birthdate,
      mobile: user.mobile,
      email: user.email,
      function: user.function,
    });

    this.fg.controls.cpf.disable();
    this.fg.controls.function.disable();
    this.loading = false;
  }

  onChangeEmail(){
    if(!this.loginService.isEmailPatternValid(this.fg.controls.email.value)) {
      this.fg.controls.email.setErrors({
        invalid: true
      })
    }
  }

  onChangePassword(): void {
    let password = this.fg.controls['password'].value || '';
    this.passStrength.checkForcaSenha(password);
    this.tips = this.passStrength.dicas.join('<br/>');

    this.confirmPassword();
  }

  confirmPassword() {
    const password = this.fg.controls['password'].value;
    const repassword = this.fg.controls['repassword'].value;
    if (repassword != password)
      this.fg.controls['repassword'].setErrors({ notSame: true });
    return;
  }

  onChangeRepassword(): void {
    this.fg.controls['repassword'].markAsTouched();
    this.confirmPassword();
  }

  save(){

    if(this.fg.invalid){
      this.loginService.showMessage('Preencha os campos corretamente', 4000);
      this.fg.markAllAsTouched();
      return; 
    }

    if(this.passStrength.dicas.length !== 0){
      this.loginService.showMessage('Siga as dicas para uma senha mais forte', 5000);
      return; 
    }

    const params = this.fg.getRawValue();
    if(!this.showCanceled)
      delete params.password;
    
    delete params.repassword;

    this.userService.completeUserInvitation(this.token, params).subscribe(
      res=>{

        if(res.status){
          LocalStorageUtil.setToken(res.data.accessToken);
          this.signatureService
          .getAccountSignature()
          .subscribe(
            (signature) => {
              if (signature) {
                LocalStorageUtil.setAccountInfo(res.data.accountInfo)

                this.router.navigate(['/indications'])
              }

              this.loading = false;
            },
            (err) => {
              console.error('Assinatura não encontrada!');
              this.loading = false;
            }
          );
        }else{
          this.loginService.showMessage('Erro ao ativar cadastro')
          this.loading = false;
        }
      },
      error=>{
        this.loginService.showMessage('Erro ao ativar cadastro')
        this.loading = false;
      }
    )
  }

  cancel(){
    this.location.back();
  }
  

}
