import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { ChangePlanService } from '../../services/change-plan.service';

@Component({
  selector: 'app-change-plan',
  templateUrl: './change-plan.component.html',
  styleUrls: ['./change-plan.component.scss']
})
export class ChangePlanComponent {
  form: FormGroup;
  loading = false;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly loginService: LoginService,
    private readonly changePlanService: ChangePlanService
  ) {
    this.form = this.formBuilder.group({
      iuguId: ['', Validators.required],
      iuguSignatureId: ['', Validators.required],
      maxUsers: [null, [Validators.required, Validators.min(0)]]
    });
  }

  submit() {
    if (this.form.valid) {
      this.loading = true;
      this.changePlanService.changePlan(this.form.value)
        .subscribe({
          next: () => {
            this.loading = false;
            this.loginService.showMessage('Plano alterado com sucesso!');
            this.form.reset();
          },
          error: (err) => {
            this.loading = false;
            this.loginService.showMessage(err.error.message);
          }
        });
    }
  }
}
