import { Component } from '@angular/core';
import { LoginService } from './services/login.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SignatureService } from './services/signature.service';
import { AccountSignature } from './pages/signature/signature.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [],
})
export class AppComponent {
  public update: boolean = false;
  public version
  private accountSignature: AccountSignature;
  constructor(
    loginService: LoginService,
    signatureService: SignatureService,
    snack: MatSnackBar
  ) {

    if(window.location.hash !== '#/login'){
      signatureService.getAccountSignature().subscribe(res=>{
        if(res)
          this.accountSignature = res.data
      })
    }
    
    loginService.notification$.subscribe((notification) => {
      snack.open(notification.message, 'OK', {
        duration: notification.duration || 5000
      })
    })
  }
}
