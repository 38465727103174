import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { Views } from '../views';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DefaultResponse } from '../base.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { UsersService } from 'src/app/services/users.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends Views implements OnInit {

  constructor(
    private fb: UntypedFormBuilder,
    private snack: MatSnackBar,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService,
    private userService: UsersService,
  ) { 
    super();
    this.loading = false;
    this.token = this.activatedRoute.snapshot.params['token'];
  }

  viewType: 'forgot' | 'reset' = 'forgot';
  token = ''
  validToken = true;

  public logo: string = 'assets/logoRegister.png';

  ngOnInit(): void {

    if(this.activatedRoute.snapshot.routeConfig.path == 'reset-password/:token'){
      this.viewType = 'reset';
      this.campos = {
        newPass: [Validators.required],
        confirmNewPass: [Validators.required],
      }
      this.checkToken();
    }else{
      this.campos = {
        cpf: [Validators.required],
      }
    }

    this.initForm(this.fb);
  }

  checkToken(){
    this.loading = true;

    this.loginService.checkToken(this.token).subscribe(
      res=>{
        if(res.status){
          this.validToken = true;
        }else{
          this.validToken = false;
          this.loginService.showMessage('Aguarde, estamos redirecionando...', 6000);
          setTimeout(()=>{
            this.router.navigate(['/login']);
          },5000)
        }

        this.loading = false;
      },
      error=>{
        this.validToken = false;
        this.loginService.showMessage('Aguarde, estamos redirecionando...', 6000);
        setTimeout(()=>{
          this.router.navigate(['/login']);
        },5000)
        this.loading = false;
      }
    )
  }

  onChangeCPF(){
    if(!this.loginService.isCpfPatternValid(this.fg.controls.cpf.value)) {
      this.fg.controls.cpf.setErrors({
        invalid: true
      })
    }
  }

  onChooseAction(){
    if(this.viewType == 'forgot')
      this.forgotPassword();
    else
      this.resetPassword();
  }

  forgotPassword(){

    if (this.fg.invalid) {
      this.loginService.showMessage('Campos incorretos', 3000);
      return;
    }

    this.loading = true;
    this.userService
    .forgotPassword({
      cpf: this.fg.value.cpf,
    })
    .subscribe(
      (resp: DefaultResponse<{email: string}>) => {
        if(resp.status){
          let msg = `E-mail de mudança de senha enviado para ${resp.data.email}`;

          this.snack.open(msg, 'Ok', 
            {
              duration: 20000
            }
          );
          this.router.navigate(['/login']);
        }else
          this.loginService.showMessage('Usuário não encontrado. Verifique o CPF', 3000);
        
        this.loading = false;
      },
      (err: HttpErrorResponse) => {
          this.loading = false;
          this.loginService.showMessage('Erro ao enviar e-mail de mudança de senha', 3000);
      },
    );
  }

  resetPassword(){

    if (this.fg.controls.newPass.value != this.fg.controls.confirmNewPass.value) {
      this.loginService.showMessage('Senhas não conferem!', 3000);
      return;
    }

    this.loading = true;
    this.loginService
      .resetPassword(this.fg.value.newPass, this.token)
      .subscribe(
        (resp: DefaultResponse<void>) => {
          if(resp.status){
            this.loginService.showMessage('Senha modificada com sucesso', 3000);
            this.router.navigate(['/login']);
          }else
            this.loginService.showMessage('Senha não modificada, tente novamente', 3000);
          
          this.loading = false;
        },
        (err: HttpErrorResponse) => {
            this.loading = false;
            this.loginService.showMessage('Erro ao modificar senha', 3000);
        },
      );
    
  }

}
