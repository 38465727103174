import { Views } from 'src/app/pages/views';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { LocalStorageUtil } from 'src/app/util/local-storage-util';
import { MSG } from '../util/constants';
import { LoginService } from './login.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  className = ''
  constructor(
    private snack: MatSnackBar,
    private router: Router,
  ) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request.clone({ headers: this.getHeaders(request.url) }))
      .pipe(
        retry(0),
        catchError((error: HttpErrorResponse) => {
          if (!this.shouldRedirect(error, request)) {
            return throwError(error);
          }

          this.logoutAndRedirect();
          return throwError(error.error.message || error.statusText);
        }),
      );
  }

  private shouldRedirect(error, request) {
    const isForbidden = error.status === 401 || error.status === 403;
    const isLogin = request.url == '/login' || request.url.match('isModal=true') || !request.url.match('check?token=');
    return isForbidden && !isLogin;
  }

  private logoutAndRedirect() {
    LocalStorageUtil.logout();
    this.snack.open(MSG.LOGIN.LOGIN_EXPIRED, '', { duration: 5000, panelClass: this.className });
    this.router.navigate(['/login'], { queryParamsHandling: 'preserve' });
  }

  private getHeaders(url) {
    if (url.indexOf('slack') !== -1) {
      return new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      });
    }

    let headersObj: any = {
      'Access-Control-Allow-Origin': '*',
    };

    if (url.indexOf('upload') == -1) 
      headersObj['Content-Type'] = 'application/json'


    if (!url.includes(['/login', '/cadastro'])) {
      headersObj.Authorization = 'Bearer ' + LocalStorageUtil.getToken();
    }
    return new HttpHeaders(headersObj);
  }
}
