export enum SignaturePlansNames {
  FREE = 'free',
  ESSENTIAL = 'essential',
  PLUS = 'plus'
}

export interface SignaturePlans {
  identifier: SignaturePlansNames,
  name: string,
  value: number,
  maxUsers: number,
  schedulePrice: string,
  features: PlanFeature[]
}

export interface AccountSignature {
  active: boolean;
  suspended: boolean;
  expiresAt?: string;
  createdAt?: string;
  identifier?: SignaturePlansNames;
  hasDefaultPaymentMethod?: boolean;
  addedUsers?: number;
}

export interface PlanFeature{
  identifier: string, 
  value: number
  name: string
}