<div align="right">
  <button
    mat-mini-fab
    mat-dialog-close
    type="button"
    class="red"
  >
    <fa-icon icon="times"></fa-icon>
  </button>
</div>

<h2 mat-dialog-title *ngIf="data.title">{{ data.title }}</h2>
<mat-dialog-content>
  <fa-icon icon="exclamation-circle" class="especial"></fa-icon>
  <p [innerHTML]="data.question"></p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="action()" class="save">
    <fa-icon icon="check"></fa-icon> {{ data.confirm }}
  </button>
</mat-dialog-actions>
