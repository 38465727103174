import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login.service';
import { UsersService } from 'src/app/services/users.service';
import { PasswordStrength } from 'src/app/util/password-strength.class';

@Component({
  selector: 'app-ui-change-password',
  templateUrl: './ui-change-password.component.html',
  styleUrls: ['./ui-change-password.component.scss']
})
export class UiChangePasswordComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UiChangePasswordComponent>,
    private loginService: LoginService,
    private userService: UsersService,
  ) { }

  fg = new UntypedFormGroup({
    password: new UntypedFormControl(null,[Validators.required]),
    repassword: new UntypedFormControl(null,[Validators.required])
  });

  passStrength = new PasswordStrength();
  tips = '';
  typeSenha = 'password';

  loading = false;

  ngOnInit(): void {}

  onChangePassword(): void {
    let password = this.fg.controls['password'].value || '';
    this.passStrength.checkForcaSenha(password);
    this.tips = this.passStrength.dicas.join('<br/>');

    this.confirmPassword();
  }

  confirmPassword() {
    const password = this.fg.controls['password'].value;
    const repassword = this.fg.controls['repassword'].value;
    if (repassword != password)
      this.fg.controls['repassword'].setErrors({ notSame: true });
    return;
  }

  onChangeRepassword(): void {
    this.fg.controls['repassword'].markAsTouched();
    this.confirmPassword();
  }

  save(){
    if(this.fg.invalid){
      this.loginService.showMessage('Preencha os campos corretamnte', 3000);
      return;
    }
    
    if(this.passStrength.dicas.length !== 0){
      this.loginService.showMessage('Siga as dicas para uma senha mais forte', 5000);
      return; 
    }

    this.loading = true;
    this.userService.changePassword(this.fg.getRawValue().password).subscribe(
      res=>{

        if(res.status){
          this.loginService.showMessage('Senha alterada com sucesso', 3000);
          this.dialogRef.close();
        }else{
          this.loginService.showMessage('Erro ao atualizar senha, tente novamente')

          this.loading = false;
        }
      },
      error=>{
        this.loginService.showMessage('Erro ao atualizar senha, tente novamente')
        this.loading = false;
      }
    )
  }

}
