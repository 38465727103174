import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CardDialogComponent } from 'src/app/shared/components/card-dialog/card-dialog.component';
import { AccountSignature, PlanFeature, SignaturePlans, SignaturePlansNames } from './signature.interface';
import { SignatureService } from 'src/app/services/signature.service';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import { LocalStorageUtil } from 'src/app/util/local-storage-util';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private signatureService: SignatureService,
    private loginService: LoginService,
  ) { }

  loading = true;
  signature: AccountSignature = null;
  signaturePlansNames = SignaturePlansNames;
  signaturePlans: SignaturePlans[] = [];

  additionalUsersInfo: PlanFeature;

  fg = new UntypedFormGroup({
    quantity: new UntypedFormControl(null),
    value: new UntypedFormControl(null),
  });

  ngOnInit(): void {
    this.getSignatureInformations();
    this.fg.controls.value.disable();
    this.fg.controls.quantity.valueChanges.subscribe(res=>{
      this.caculateAdditionalUserValue();
    });
  }

  caculateAdditionalUserValue(){
    const quantity = this.fg.getRawValue().quantity;
    this.fg.get('value').setValue( ((this.additionalUsersInfo.value/100) * quantity))
  }

  async getSignatureInformations(){
    this.loading = true;

    try {
      const signature = await this.signatureService.getAccountSignature().toPromise()
      const plans = await this.signatureService.getPlans(
        signature ? signature.data.identifier : null
      ).toPromise()
      this.setIuguPlans(plans)
      this.setSignature(signature)
      this.loading = false
    } catch(error) {
      this.loginService.showMessage('Erro ao carregar informações de assinaturas e planos');
      this.loading = false;
    }
  }

  setIuguPlans(res){
    if(res.data.length > 0){
      this.signaturePlans = res.data.map((plan:SignaturePlans) =>{
        switch (plan.identifier) {
          case SignaturePlansNames.FREE:
            plan.maxUsers = 1;
            plan.schedulePrice = '397,00';
            break;
          case SignaturePlansNames.ESSENTIAL:
            plan.maxUsers = 2;
            plan.schedulePrice = '297,00';
            break;
          case SignaturePlansNames.PLUS:
            plan.maxUsers = 5;
            plan.schedulePrice = '197,00';
            this.additionalUsersInfo = plan.features[0];
            break;
          default:  // Custom plans
            const maxUsers = plan.features.find(f => f.identifier === 'max_users');

            if(maxUsers) {
              plan.maxUsers = Number(maxUsers.name)
            } else {
              plan.maxUsers = 1
            }
            break;
        }

        return plan;
      });

      this.signaturePlans = [...this.signaturePlans].filter(plan => plan.identifier !== SignaturePlansNames.FREE);
    }
  }

  setSignature(signatureResp){
    if (signatureResp) {
      this.signature = signatureResp.data;
      if(this.signature.identifier == SignaturePlansNames.PLUS && this.signature.addedUsers !== 0){
        this.fg.controls.quantity.setValue(this.signature.addedUsers)
        this.fg.controls.value.setValue(((this.additionalUsersInfo.value/100) * this.signature.addedUsers))
      }
    }
  }

  onChoosePlan(plan: SignaturePlans){
    if(!this.signature || !this.signature.active){

      if(plan.identifier !== this.signaturePlansNames.FREE){
        const dialogRef = this.dialog.open(CardDialogComponent,{
          data: {
            hasDefaultPaymentMethod: false,
            plan
          }
        })

        dialogRef.afterClosed().subscribe(res=>{
          if(res)
            this.createSignature(plan, res.token, res.installments)

        })

      }else
        this.createSignature(plan);
    }else
      this.changePlan(plan)
  }

  openIntercomChat() {
    this.loginService.showIntercom();
  }

  createSignature(plan: SignaturePlans, cardToken = null, installments = 1){
    this.loading = true;
    this.signatureService.createSignature(plan, cardToken, installments).subscribe(
      res =>{
        this.loginService.showMessage('Plano contratado com sucesso', 3000);

        this.signatureService.accountSignatureInfo ={
          active: res.data?.active,
          suspended: res.data?.suspended,
          identifier: res.data?.identifier
        }

        LocalStorageUtil.setSignature({
          active: res.data?.active,
          suspended: res.data?.suspended,
          identifier: res.data?.identifier
        })

        this.signature = res.data;
        this.router.navigate(['/indications']);
        this.loading = false;
      },
      error =>{
        this.loginService.showMessage('Erro ao assinar, tente novamente', 3000)
        this.loading = false;
      }
    )

  }

  changePlan(plan: SignaturePlans) {
    if(plan.identifier !== this.signaturePlansNames.FREE){
      const dialogRef = this.dialog.open(CardDialogComponent,{
        data: {
          hasDefaultPaymentMethod: this.signature.hasDefaultPaymentMethod,
          plan
        }
      })

      dialogRef.afterClosed().subscribe(res=>{
        if(res){
          this.loading = true;
          this.signatureService.changeSubscription(plan, res.token, res.installments).subscribe(
            res => {
              if(!res.status){
                this.loginService.showMessage(res.error + ', tente novamente', 3000)
                this.loading = false;
              } else {
                this.loginService.showMessage('Plano modificado com sucesso', 3000)

                this.signatureService.accountSignatureInfo={
                  active: res.data?.active,
                  suspended: res.data?.suspended,
                  identifier: res.data?.identifier
                }

                LocalStorageUtil.setSignature({
                  active: res.data?.active,
                  suspended: res.data?.suspended,
                  identifier: res.data?.identifier
                })

                this.signature.identifier = plan.identifier;
                this.loading = false;
              }

            },
            error => {
              this.loginService.showMessage('Erro ao mudar de plano, tente novamente', 3000)
              this.loading = false;
            }
          );
        }
      })

    }else{
      this.loading = true;

      this.signatureService.changeSubscription(plan).subscribe(
        res => {
          this.loginService.showMessage('Plano modificado com sucesso', 3000)
          this.loading = false;
        },
        error => {
          this.loginService.showMessage('Erro ao mudar de plano, tente novamente', 3000)
          this.loading = false;
        }
      );
    }

  }

  setUsers(){
    const contractUsers = this.fg.getRawValue();

    const data = {
      quantity:this.fg.getRawValue().quantity,
      ...this.additionalUsersInfo
    }

    this.loading = true;
    this.signatureService.addUsersToSignature(data).subscribe(
      res => {
        if(res.status)
          this.loginService.showMessage('Plano atualizado com sucesso', 3000);
        else
          this.loginService.showMessage('Erro ao atualizar de plano, tente novamente', 3000);

        this.loading = false;
      },
      error => {
        this.loginService.showMessage('Erro ao atualizar de plano, tente novamente', 3000)
        this.loading = false;
      }
    );
  }

}
