<app-ui-main-menu>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Usuários</mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content>
      <div  class="addUser">
        <button mat-flat-button class="btn-primary" (click)="onSetUser()">Adicionar usuário</button>
      </div>
      <mat-tab-group>
        <mat-tab label="Ativos"> 
          <ng-template matTabContent>
            <app-user-list
              [status]="'active'"
            ></app-user-list>  
          </ng-template>
        </mat-tab>

        <mat-tab label="Pendentes">
          <ng-template matTabContent>
            <app-user-list
              [status]="'pending'"
            ></app-user-list>  
          </ng-template>
        </mat-tab>

        <mat-tab label="Inativos">
          <ng-template matTabContent>
            <app-user-list
              [status]="'inactive'"
            ></app-user-list>
          </ng-template>
        </mat-tab>

      </mat-tab-group>

    </mat-card-content>
  </mat-card>
</app-ui-main-menu>