<app-loading *ngIf="loading"></app-loading>
<ng-container *ngIf="!loading">
  <div *ngIf="dataSource.data.length == 0">
    <h3>Nenhum dado encontrado</h3>
  </div>
  
  <div *ngIf="dataSource.data.length > 0">
      <table mat-table [dataSource]="dataSource" class="mat-table">
        
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Usuário </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
    
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email </th>
          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>
    
        <ng-container matColumnDef="function">
          <th mat-header-cell *matHeaderCellDef> Perfil </th>
          <td mat-cell *matCellDef="let element"> {{element.function}} </td>
        </ng-container>
    
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Ações </th>
          <td mat-cell *matCellDef="let element">
              <button 
                  mat-button 
                  *ngIf="status == 'active' || status == 'pending'"
                  matTooltip="Editar"
                  matTooltipPosition="above"
                  (click)="onEditUser(element.id)"
              >
                  <fa-icon icon="pencil-alt"></fa-icon>
              </button>
              <button 
                  mat-button 
                  *ngIf="status == 'inactive'"
                  matTooltip="Ativar"
                  matTooltipPosition="above"
                  (click)="onChangeStatus(element.id)"
              >
                  <fa-icon icon="undo"></fa-icon>
              </button>
              <button 
                  mat-button 
                  *ngIf="status == 'active'"
                  matTooltip="Remover"
                  matTooltipPosition="above"
                  (click)="onChangeStatus(element.id)"
              >
                  <fa-icon icon="minus-circle"></fa-icon>
              </button>
              <button 
                  mat-button 
                  *ngIf="status == 'pending'"
                  matTooltip="Reenviar convite"
                  matTooltipPosition="above"
                  (click)="sendInvitation(element.id)"
                  [disabled]="sendingEmail"
              >
                  <mat-spinner *ngIf="sendingEmail" [diameter]="25"></mat-spinner>  
                  <fa-icon *ngIf="!sendingEmail" icon="paper-plane"></fa-icon>
              </button>
          </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
</ng-container>
