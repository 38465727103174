<div align="right">
  <button
    mat-mini-fab
    mat-dialog-close
    type="button"
    class="red"
  >
    <fa-icon icon="times"></fa-icon>
  </button>
</div>

<mat-dialog-content>
  <mat-list>
    <mat-list-item *ngFor="let documentUrl of data.documents; let i = index">
      <a [href]="documentUrl" target="_blank">Laudo {{ i + 1 }}</a>
    </mat-list-item>
  </mat-list>
</mat-dialog-content>
