import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IndicationsService } from 'src/app/services/indications.service';
import { LoginService } from 'src/app/services/login.service';
import { ConfirmationDialog, UiConfirmationDialogComponent } from 'src/app/shared/components/ui-confirmation-dialog/ui-confirmation-dialog.component';

@Component({
  selector: 'app-agenda-online-modal',
  templateUrl: './agenda-online-modal.component.html',
  styleUrls: ['./agenda-online-modal.component.scss']
})
export class AgendaOnlineModalComponent implements OnInit {
  loading = true
  url = null

  constructor(
    private indicationsService: IndicationsService,
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: {
      communicarePatientId: number,
      showSuccessMessage: boolean,
      rescheduleId: number | null,
    },
    public dialogRef: MatDialogRef<AgendaOnlineModalComponent>,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    window.addEventListener("message", this.receiveMessage, false);

    this.indicationsService.generateSSOLink(this.data.communicarePatientId)
      .subscribe(resp => {
        const rescheduleUrl = this.data.rescheduleId ? `/${this.data.rescheduleId}` : '/null'
        this.url = `${resp.url}${rescheduleUrl}`;
        this.loading = false
      }, (error) => {
        this.loginService.showMessage('Erro!')
        this.dialogRef.close()
      })
  }

  receiveMessage = (event) => {
    if (event.data == 'close'){
      this.loginService.showMessage('Agendamento realizado com sucesso!')
      this.dialogRef.close()
    }
  }

  closeModal(){
    const dialogRef = this.dialog.open(UiConfirmationDialogComponent, {
      data: {
        title: 'Atenção!',
        question: `Já concluiu o agendamento?`,
        confirm: 'Sim',
        cancel: 'Não'
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result: ConfirmationDialog) => {
      if (result.confirm) {
        this.dialogRef.close()
      }
    });
  }

}
