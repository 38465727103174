<h2 mat-dialog-title>Alterar senha</h2>
<mat-dialog-content >
    <app-loading *ngIf="loading"></app-loading>
    <form [formGroup]="fg" *ngIf="!loading">
        <mat-form-field appearance="outline" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 roundedField">
            <mat-label>Senha</mat-label>
            <input
              matInput
              [type]="typeSenha"
              appNoAutoComplete
              formControlName="password"
              placeholder="Senha"
              (input)="onChangePassword()"
              required
            />
            <span class="eye" matSuffix>
              <fa-icon
                icon="eye"
                *ngIf="typeSenha === 'password'"
                (click)="typeSenha = 'text'"
              ></fa-icon>
              <fa-icon
                icon="eye-slash"
                *ngIf="typeSenha === 'text'"
                (click)="typeSenha = 'password'"
              ></fa-icon>
            </span>
            <span matSuffix> </span>
            <mat-error *ngIf="fg.hasError('required', ['password'])">
              <strong>Por favor digite uma senha</strong>
            </mat-error>
            <mat-hint
              *ngIf="
                passStrength.visivel &&
                passStrength.iniciado &&
                fg.controls['password'].value
              "
            >
              {{ passStrength.dica }}
              <mat-progress-bar
                *ngIf="passStrength.visivel && passStrength.iniciado"
                [value]="passStrength.valor"
                [color]="passStrength.cor"
              >
              </mat-progress-bar>
            </mat-hint>
            <div
                class="tips-box"
                *ngIf="
                passStrength.visivelDicas &&
                passStrength.iniciado &&
                fg.controls['password'].value
                "
            >
                <br />
                <div align="left">{{ passStrength.titulo }}</div>
                <br />
    
                <span class="tips" [innerHTML]="tips"></span>
            </div>
        </mat-form-field>
    
        <mat-form-field appearance="outline" class="roundedField col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <mat-label>Confirmação de senha</mat-label>
            <input
                matInput
                [type]="typeSenha"
                appNoAutoComplete
                formControlName="repassword"
                placeholder="Confirmação de senha"
                required
                (change)="onChangeRepassword()"
                (input)="onChangeRepassword()"
            />
            <span class="eye" matSuffix>
                <fa-icon
                icon="eye"
                *ngIf="typeSenha === 'password'"
                (click)="typeSenha = 'text'"
                ></fa-icon>
                <fa-icon
                icon="eye-slash"
                *ngIf="typeSenha === 'text'"
                (click)="typeSenha = 'password'"
                ></fa-icon>
            </span>
            <span matSuffix> </span>
            <mat-error *ngIf="fg.hasError('notSame', ['repassword'])">
                <strong>Senhas não coincidem! Por favor verifique.</strong>
            </mat-error>
            <mat-error *ngIf="fg.hasError('required', ['repassword'])">
                <strong>Por favor confirme sua senha!</strong>
            </mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions *ngIf="!loading">
    <button mat-button class="btn-cancel" mat-dialog-close>Cancelar</button>
    <button mat-button class="btn-green" (click)="save()">Salvar</button>
  </mat-dialog-actions>
