import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';

import {
  faUsers,
  faHistory,
  faCalendarAlt,
  faEllipsisV,
  faPlus,
  faTimes,
  faEdit,
  faTimesCircle,
  faCheckCircle,
  faCalendarPlus,
  faUserCheck,
  faUserTimes,
  faCommentSlash,
  faCalendarTimes,
  faArrowLeft,
  faArrowRight,
  faPlusCircle,
  faTrash,
  faTrashAlt,
  faCheck,
  faExclamationCircle,
  faDollarSign,
  faPaperclip,
  faVideo,
  faInfo,
  faMoneyCheckAlt,
  faDatabase,
  faCopy,
  faCreditCard,
  faChevronUp,
  faChevronDown,
  faEye,
  faEyeSlash,
  faAngleRight,
  faUser,
  faSearch,
  faAsterisk,
  faUndo,
  faPencilAlt,
  faMinusCircle,
  faPaperPlane,
  faTv,
  faBriefcaseMedical,
  faFileInvoice
} from '@fortawesome/free-solid-svg-icons';

import {
  faCalendarCheck,
  faImage,
  faCheckCircle as faCheckCircleRegular,
  faUserCircle
} from '@fortawesome/free-regular-svg-icons';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [FontAwesomeModule],
})
export class LocalFontAwesome {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faUsers,
      faHistory,
      faCalendarAlt,
      faEllipsisV,
      faPlus,
      faTimes,
      faEdit,
      faTimesCircle,
      faCheckCircle,
      faCalendarPlus,
      faUserTimes,
      faUserCheck,
      faCommentSlash,
      faCalendarTimes,
      faCalendarCheck,
      faImage,
      faArrowLeft,
      faArrowRight,
      faUser,
      faCheckCircleRegular,
      faPlusCircle,
      faUserCircle,
      faTrash,
      faTrashAlt,
      faCheck,
      faExclamationCircle,
      faDollarSign,
      faPaperclip,
      faVideo,
      faInfo,
      faMoneyCheckAlt,
      faDatabase,
      faCopy,
      faCreditCard,
      faChevronUp,
      faChevronDown,
      faEye,
      faEyeSlash,
      faAngleRight,
      faSearch,
      faAsterisk,
      faUndo,
      faPencilAlt,
      faMinusCircle,
      faPaperPlane,
      faTv,
      faBriefcaseMedical,
      faFileInvoice
    );
  }
}
