import { environment } from "src/environments/environment";
import { SignaturePlansNames } from "../pages/signature/signature.interface";

export interface AccountInfo {
  accountName: string;
  userName: string;
  admin: boolean;
  userId?: any,
  userEmail?: string,
  createdAt?: number,
  superAdmin?: boolean,
}

export namespace LocalStorageUtil {
  export function setAccountInfo(account: AccountInfo) {
    localStorage.setItem('account-info', JSON.stringify(account));
  }

  export function getAccountInfo(): AccountInfo {
    return JSON.parse(localStorage.getItem('account-info'));
  }

  export function setToken(token: string) {
    localStorage.setItem('token', token);
  }

  export function getToken(): string {
    return localStorage.getItem('token');
  }

  export function setSignature(signature: {
    active: boolean,
    suspended: boolean,
    identifier: SignaturePlansNames
  } = null) {
    localStorage.setItem('signature', JSON.stringify(signature));
  }

  export function getSignature(): {
    active,
    supended,
    identifier: string
  } {
    return JSON.parse(localStorage.getItem('signature'));
  }

  export function logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('account-info');
    localStorage.removeItem('signature');

    if(environment.intercom && window.Intercom) {
      window.Intercom('shutdown')
    }
  }
}
