<app-ui-main-menu>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Clientes</mat-card-title>

      <mat-card-content class="action-container">
        <div class="field-container">
          <mat-form-field
            appearance="outline"
            [floatLabel]="'always'"
          >
            <mat-label>Buscar</mat-label>
            <input
              matInput
              type="text"
              [(ngModel)]="search"
              [ngModelOptions]="{ standalone: true }"
              (keyup)="onKeyUpBuscar($event)"
              placeholder="Buscar por nome, CPF, nascimento ou prontuário"
            />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Filtros</mat-label>
            <mat-select [(ngModel)]="filter" (selectionChange)="onChangeFilter()" >
              <mat-option *ngFor="let f of filters" [value]="f.id">
                {{ f.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="button-container">
          <button mat-button (click)="onClickBuscar()" [disabled]="loading">
            <span class="icon-container">
              <mat-spinner [diameter]="18" *ngIf="loading" class="btn-white"></mat-spinner>
              <fa-icon icon="search" *ngIf="!loading"></fa-icon>
            </span>
            <span>Buscar</span>
          </button>
          <button
            *ngIf="showAddPatient"
            mat-button
            (click)="goToPatientRegister()"
            class="actionButton"
          >
            <fa-icon icon="plus"></fa-icon>
            Adicionar
          </button>
        </div>
      </mat-card-content>
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-card-content class="table-container">
      <div *ngIf="loading" class="loader">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>
      <div class="row" *ngIf="!loading">
        <div class="col-sm-12" *ngIf="patientList?.data?.length <= 0">
          Nenhum cliente encontrado!
        </div>

        <div class="col-sm-12" *ngIf="patientList?.data?.length > 0">
          <div class="box-table">
            <table
              mat-table
              matSort
              (matSortChange)="sort($event)"
              [dataSource]="patientList"
            >
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Nome
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="cpf">
                <th mat-header-cell *matHeaderCellDef>CPF</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.cpf === null ? '-' : ''
                  }}{{ element.cpf | mask : '999.999.999-99' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="birthdate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Nascimento
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.birthdate | date: 'DD/MM/YYYY' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="mobile">
                <th mat-header-cell *matHeaderCellDef>Celular</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.mobile === null ? '-' : ''
                  }}{{ element.mobile | mask : '(99) 99999-9999' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Ações</th>
                <td mat-cell *matCellDef="let element" class="acoes-tab">
                  <button
                    mat-mini-fab
                    type="button"
                    matTooltip="Editar"
                    matTooltipPosition="above"
                    [routerLink]="['register', element.id]"
                    class="btn-action"
                  >
                    <fa-icon icon="pencil-alt"></fa-icon>
                  </button>

                  <button
                    mat-mini-fab
                    type="button"
                    *ngIf="!element.isDeleted"
                    matTooltip="Inativar cliente"
                    matTooltipPosition="above"
                    (click)="changeIsDeleted(element)"
                    class="btn-action-inactivate"
                  >
                    <fa-icon icon="times"></fa-icon>
                  </button>

                  <button
                    mat-mini-fab
                    type="button"
                    *ngIf="element.isDeleted"
                    matTooltip="Reativar cliente"
                    matTooltipPosition="above"
                    (click)="changeIsDeleted(element)"
                    class="btn-action-reactivate"
                  >
                    <fa-icon icon="undo"></fa-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="columns"></tr>

              <!-- <tr
                mat-row
                *matRowDef="let row; columns: columns; let element"
                patientTooltip
                [tooltipPatientId]="element.id"
                [tooltipPatientName]="element.name"
              ></tr> -->
              <tr
                mat-row
                *matRowDef="let row; columns: columns; let element"
                patientTooltip
              ></tr>
            </table>
          </div>
          <div class="pre-loader" *ngIf="searching">
            <mat-spinner [diameter]="50"></mat-spinner>
          </div>
          <mat-paginator
            [length]="paginatorLength"
            [pageSize]="paginatorPageSize"
            [hidePageSize]="true"
            showFirstLastButtons="true"
            (page)="onChangePage($event)"
          >
          </mat-paginator>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</app-ui-main-menu>

