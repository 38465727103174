<div>
  <nav class="navbar">
    <b matTooltip="{{ account.accountName }}">
      {{ firstName(account.accountName) }}
    </b>

    <div>
      <img src="assets/logoNavbar.png" class="imgLogo"/>
    </div>

    <div>
      <button 
        mat-menu-item 
        [matMenuTriggerFor]="sub_menu" 
        class="btn-user" 
        matTooltip="Menu de usuário"
      >
        <fa-icon icon="user"></fa-icon>&nbsp;
        {{ firstName(account.userName) }}
      </button>
      <mat-menu #sub_menu="matMenu">
        <button
          mat-menu-item
          *ngIf="!userRegistration"
          (click)="goToProfile()"
        >
          Dados cadastrais
        </button>
        <button
          mat-menu-item
          *ngIf="!userRegistration"
          (click)="resetPass()"
        >
          Alterar senha
        </button>

        <button
          mat-menu-item
          (click)="logout()"
        >
          Sair
        </button>
      </mat-menu>
    </div>
  </nav>

  <section>
    <mat-card class="menu-aside" *ngIf="!userRegistration">
      <div *ngFor="let item of menu">
        <button mat-button class="btn-menu" *ngIf="item.submenu.length == 0">
          <fa-icon *ngIf="item.icon" [icon]="item.icon"></fa-icon>
          <b class="hideOnMobile">{{ item.label }}</b>
        </button>

        <ng-container *ngIf="item.submenu.length > 0">
          <button mat-menu-item [matMenuTriggerFor]="sub_menu">
            <div>
              <fa-icon *ngIf="item.icon" [icon]="item.icon"></fa-icon>&nbsp;
              <span class="hideOnMobile">{{ item.label }}</span>&nbsp;
              <fa-icon icon="angle-right" align="right"></fa-icon>
            </div>
          </button>
          <mat-menu #sub_menu="matMenu">
            <div *ngFor="let subItem of item.submenu">
              <button
                mat-menu-item
                (click)="onClick(subItem)"
              >
                {{ subItem.label }}
              </button>
            </div>
          </mat-menu>
        </ng-container>
      </div>
    </mat-card>

    <div class="content">
      <ng-content></ng-content>
    </div>
  </section>
</div>