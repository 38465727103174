import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TakeAccountComponent } from './pages/take-account/take-account.component';
import { SharedModule } from '../shared/shared.module';
import { ChangePlanComponent } from './pages/change-plan/change-plan.component';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { LocalMaterialModule } from 'src/app/shared/modules/material/material.module';
import { LocalFontAwesome } from 'src/app/shared/modules/fontawesome/fontawesome.module';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatToolbarModule,
    MatButtonModule,
    BrowserModule,
    LocalMaterialModule,
    LocalFontAwesome,
    HttpClientModule
  ],
  declarations: [
    TakeAccountComponent,
    ChangePlanComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AdminModule {}
