import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaskModule } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { ErrorInterceptor } from './services/Interceptor';
import { LoginService } from './services/login.service';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { LocalFontAwesome } from './shared/modules/fontawesome/fontawesome.module';
import { LocalMaterialModule } from './shared/modules/material/material.module';
import { DatePipe } from './shared/pipes/date.pipe';
import { InitialLettersPipe } from './shared/pipes/initial-letters.pipe';
import { ConcatPipe } from './shared/pipes/concat.pipe';
import { PartialPipe } from './shared/pipes/partial.pipe';
import { RegisterComponent } from './pages/register/register.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { IndicationsComponent } from './pages/indications/indications.component';
import { PatientsComponent } from './pages/patients/patients.component';
import { SignatureComponent } from './pages/signature/signature.component';
import { ContractorComponent } from './pages/contractor/contractor.component';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { UsersComponent } from './pages/users/users.component';
import { CardDialogComponent } from './shared/components/card-dialog/card-dialog.component';
import { UiUploadComponent } from './shared/components/ui-upload/ui-upload.component';
import { SafePipe } from './shared/pipes/safe.pipe';
import { ImageCropperModule } from './shared/components/image-cropper/image-cropper.module';
import { PatientRegisterComponent } from './pages/patients/patient-register/patient-register.component';
import { UserListComponent } from './pages/users/user-list/user-list.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { UserComponent } from './pages/users/user/user.component';
import { IndicationsListComponent } from './pages/indications/indications-list/indications-list.component';
import { IndicationComponent } from './pages/indications/indication/indication.component';
import { UiSelectPatientComponent } from './shared/components/ui-select-patient/ui-select-patient.component';
import { InvitationComponent } from './pages/invitation/invitation.component';
import { CompleteRegistrationComponent } from './pages/complete-registration/complete-registration.component';
import { AgendaOnlineModalComponent } from './pages/indications/agenda-online-modal/agenda-online-modal.component';
import { UiAddPatientComponent } from './shared/components/ui-add-patient/ui-add-patient.component';
import { UiConfirmationDialogComponent } from './shared/components/ui-confirmation-dialog/ui-confirmation-dialog.component';
import { UiChangePasswordComponent } from './shared/components/ui-change-password/ui-change-password.component';
import { AdminModule } from './modules/admin/admin.module';
import { SharedModule } from './modules/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiSelectDocumentComponent } from './shared/components/ui-select-document/ui-select-document.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    InitialLettersPipe,
    LoadingComponent,
    DatePipe,
    ConcatPipe,
    PartialPipe,
    RegisterComponent,
    ForgotPasswordComponent,
    IndicationsComponent,
    PatientsComponent,
    SignatureComponent,
    ContractorComponent,
    InvoicesComponent,
    UsersComponent,
    CardDialogComponent,
    UiUploadComponent,
    PatientRegisterComponent,
    UserListComponent,
    UserComponent,
    IndicationsListComponent,
    IndicationComponent,
    UiSelectPatientComponent,
    InvitationComponent,
    CompleteRegistrationComponent,
    AgendaOnlineModalComponent,
    SafePipe,
    UiAddPatientComponent,
    UiConfirmationDialogComponent,
    UiSelectDocumentComponent,
    UiChangePasswordComponent
  ],
  imports: [
    BrowserModule,
    InfiniteScrollModule,
    AppRoutingModule,
    LocalMaterialModule,
    LocalFontAwesome,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    LayoutModule,
    HttpClientModule,
    ImageCropperModule,
    MatTableModule,
    MatToolbarModule,
    MatButtonModule,
    SharedModule,
    AdminModule,
  ],
  providers: [
    LoginService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    SafePipe
  ],
  entryComponents: [
    CardDialogComponent,
    UserComponent,
    AgendaOnlineModalComponent
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor() {}
}
