// TODO: Refatorar esses helpers que foram movidos do componente

export const fillZero = (h: number): string => {
  const s = h.toString(10);
  let r = '';
  for (let i = s.length; i < 4; i++) {
    r = `${r}${'0'}`;
  }
  return `${r}${s}`;
};

export const copyToClipboard = (str: string) => {
  return new Promise((res) => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    res(true);
  });
};

export const maskPhone = (phoneNum: string) => {
  phoneNum = phoneNum.replace(/\D/g, ''); //Remove tudo o que não é dígito
  phoneNum = phoneNum.replace(/^(\d{2})(\d)/g, '($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
  phoneNum = phoneNum.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
  return phoneNum;
};

export const maskCPF = (cpf: string) => {
  let result = cpf?.toString();

  if (result?.length <= 11) {
    result = result.replace(/\D/g, '');
    result = result.replace(/(\d{3})(\d)/, '$1.$2');
    result = result.replace(/(\d{3})(\d)/, '$1.$2');
    result = result.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  }

  return result;
};
