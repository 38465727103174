<app-ui-main-menu>
    <mat-card>
      <mat-card-header>
        <mat-card-title>Indicações</mat-card-title>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content>

        <form [formGroup]="fg">
            <div class="row">
                <div class="col-12 loginForm">
                    <div class="col-12 patient">

                      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12" style="padding-left: 0;">
                        <app-ui-select-patient
                          [selectedPatient]="selectedPatient"
                          name="paciente"
                          (change)="onChangePaciente($event)"
                        >
                        </app-ui-select-patient>
                      </div>
                      <div *ngIf="indicationId == 'novo'" style="padding-top: 11px;">
                        <button
                            class="btn-primary"
                            type="button"
                            (click)="addPatient()"
                            mat-button
                        >
                            Cadastrar cliente
                        </button>
                      </div>
                  </div>

                    <mat-form-field appearance="outline" class="col-12">
                        <mat-label>Fatos sobre o cliente</mat-label>
                        <textarea
                            matInput
                            formControlName="about"
                        ></textarea>
                    </mat-form-field>

                    <ng-container>
                      <div class="fileUpload">
                        <label>Anexar documentos médicos</label>
                        <input
                          type="file"
                          id="fileBtn"
                          (change)="onFileSelect($event)"
                          multiple
                        />
                        <button mat-fab color="primary" class="fileUploadBtn" (click)="triggerFileInput()">
                          <fa-icon icon="paperclip"></fa-icon>
                        </button>
                      </div>
                      <div *ngIf="activeFiles.length == 0" class="fileList">Nenhum arquivo selecionado</div>
                      <ul *ngIf="activeFiles.length > 0" class="fileList">
                        <ng-container *ngFor="let uploadFile of activeFiles; let i = index" >
                          <li *ngIf="uploadFile.isDeleted == 0" >
                            <span (click)="goToFile(uploadFile)"
                            [ngClass]="{
                              linkedText: !!uploadFile?.originalName
                            }"
                            >{{ uploadFile?.file?.name ||  uploadFile.originalName || uploadFile?.name }}</span>
                            <button
                              mat-button
                              class="removeFile"
                              matTooltip="Remover"
                              matTooltipPosition="above"
                              (click)="removeFile(i)"
                            ><fa-icon icon="times-circle"></fa-icon></button>
                          </li>
                        </ng-container>
                      </ul>
                    </ng-container>

                      <mat-form-field appearance="outline" class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <mat-label>Benefício pleiteado</mat-label>
                        <mat-select
                          formControlName="benefit"
                          (selectionChange)="onBenefitChange()"
                        >
                          <mat-option *ngFor="let benefit of benefits" [value]="benefit">
                            {{ benefit }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <ng-container *ngIf="fg.value.benefit == 'Incapacidade temporária' ">
                          <mat-form-field appearance="outline" class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <mat-label>Quantidade de dias</mat-label>
                            <mat-select
                              formControlName="quantity"
                            >
                              <mat-option *ngFor="let quantity of quantities" [value]="quantity">
                                {{ quantity }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                      </ng-container>

                </div>
            </div>
            <div class="actionBtn">
                <button class="btn-cancel" type="button" mat-button (click)="cancel()">Cancelar</button>
                <button class="btn-green" type="submit" mat-button (click)="save()">Salvar</button>
            </div>
        </form>

      </mat-card-content>
    </mat-card>
  </app-ui-main-menu>

