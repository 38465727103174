import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { LoginService } from 'src/app/services/login.service';
import { UsersService } from 'src/app/services/users.service';
import { UserComponent } from '../user/user.component';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/interfaces/user.interface';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  constructor(
    private userService: UsersService,
    private loginService: LoginService,
    private dialog: MatDialog,
  ) { }

  @Input() status: 'active' | 'pending' | 'inactive' = 'active';

  loading = false;
  sendingEmail = false;
  dataSource:  MatTableDataSource<User> = new MatTableDataSource([]);

  displayedColumns: string[] = ['name', 'email', 'function', 'action'];

  ngOnInit(): void {
    this.load();

    this.userService.userUpdated$.subscribe(() => {
      this.load();
    });
  }

  load(){
    this.loading = true;

    this.userService.getUsers(this.status).subscribe(
      res=>{
        this.dataSource.data = res.data;
        this.loading = false;
      },
      error=>{
        this.loading = false;
        this.loginService.showMessage('Erro ao buscar usuários, tente novamente', 3000);
      }
    )
  }

  onChangeStatus(id: number){
    this.loading = true;

    this.userService.onChangeStatus(id).subscribe(
      res=>{
        this.load();
      },
      error=>{
        this.loading = false;
        this.loginService.showMessage('Erro ao atualizar status de usuário, tente novamente', 3000);
      }
    )
  }

  sendInvitation(id: number){
    this.sendingEmail = true;

    this.userService.onSendInvitation(id).subscribe(
      res=>{

        if(res.status)
          this.loginService.showMessage('Email enviado com sucesso', 3000)
        else
          this.loginService.showMessage('Erro ao enviar convite ao usuário, tente novamente', 3000);

        this.sendingEmail = false;

      },
      error=>{
        this.sendingEmail = false;
        this.loginService.showMessage('Erro ao enviar convite ao usuário, tente novamente', 3000);
      }
    )
  }

  onEditUser(id: number){
    const dialogRef = this.dialog.open(UserComponent,{
      disableClose: true,
      data: {
        id
      }
    })

    dialogRef.afterClosed().subscribe(res=>{
      if(res && res.success)
        this.load()
    })
  }

}
