<div>
    <form [formGroup]="fg">
        <div class="row margin-top">
            <div class="col-12 col-md-12">
                <div class="row">
                    <div class="col-sm-12 col-lg-4">
                        <mat-form-field appearance="outline">
                            <mat-label>CPF</mat-label>
                            <input
                                matInput
                                formControlName="cpf"
                                [specialCharacters]="['/', '.', '-']"
                                [mask]="'000.000.000-00'"
                                (change)="onChangeCPF()"
                                required
                            />
                            <mat-error *ngIf="fg.get('cpf').hasError('required') && fg.get('cpf').touched">
                                CPF é obrigatório
                            </mat-error>
                            <mat-error *ngIf="fg.get('cpf').hasError('invalid') && fg.get('cpf').touched">
                                CPF inválido
                            </mat-error>
                            <mat-error *ngIf="fg.get('cpf').hasError('alreadyExist') && fg.get('cpf').touched">
                                Cliente já cadastrado
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-sm-12 col-lg-8">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome completo</mat-label>
                            <input
                                matInput
                                formControlName="name"
                                required
                            />
                            <mat-error *ngIf="fg.get('name').hasError('required') && fg.get('name').touched">
                                None completo é obrigatório
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-3">
                        <mat-form-field appearance="outline" class="birthDate">
                            <mat-label>
                                Data de nascimento
                            </mat-label>
                            <input
                                matInput
                                type="text"
                                formControlName="birthdate"
                                [specialCharacters]="['/']"
                                mask="00/00/0000"
                                placeholder="Data de nascimento"
                                required
                            />
                            <mat-error *ngIf="fg.get('birthdate').hasError('invalid') && fg.get('birthdate').touched">
                                Data de nascimento inválida
                            </mat-error>
                            <mat-error *ngIf="fg.get('birthdate').hasError('required') && fg.get('birthdate').touched">
                                Data de nascimento é obrigatória
                            </mat-error>
                        </mat-form-field>
                    </div>
                    
                    <div class="col-sm-12 col-md-6 col-lg-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Celular</mat-label>
                            <input
                                matInput
                                formControlName="mobile"
                                [specialCharacters]="['-', '(', ')', ' ']"
                                [mask]="'(00) 00000-0000'"
                                required
                            />
                            <mat-error *ngIf="fg.get('mobile').hasError('required') && fg.get('mobile').touched">
                                Celular é obrigatório
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-6">
                        <mat-form-field appearance="outline">
                            <mat-label>E-mail</mat-label>
                            <input
                                matInput
                                formControlName="email"
                                required
                                (change)="onChangeEmail()"
                            />
                            <mat-error *ngIf="fg.hasError('invalid', ['email'])">
                                E-mail inválido
                            </mat-error>
                            <mat-error *ngIf="fg.hasError('required', ['email'])">
                                E-mail é obrigatório
                            </mat-error>
                        </mat-form-field>                      
                    </div>
                </div>
                

            </div>
        </div>
        <div class="">
            <div class="action-buttons">
                <button
                    mat-button
                    class="btn-cancel"
                    mat-dialog-close
                >
                    <fa-icon icon="times"></fa-icon>
                    Cancelar
                </button>

                <button
                    mat-button
                    class="btn-green"
                    (click)="savePatient()"
                >
                    <mat-spinner
                        [diameter]="20"
                        color="primary"
                        class="branco"
                        *ngIf="saving; else btnSalvar"
                    >
                    </mat-spinner>
                    <ng-template #btnSalvar>
                        <fa-icon icon="check"></fa-icon>
                        Salvar
                    </ng-template>
                </button>
            </div>
        </div>              
    </form>
</div>
