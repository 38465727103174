<div align="right" *ngIf="!loading">
  <button
    mat-button
    class="btn-red"
    (click)="closeModal()"
  >
    <fa-icon icon="times"></fa-icon>
    Fechar modal
  </button>
</div><br>

<div class="loading-screen" *ngIf="loading">
  <mat-spinner [diameter]="50"></mat-spinner>
</div>

<iframe 
  *ngIf="url != null" 
  [src]="url | safe"
></iframe>
