import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { Patient } from 'src/app/interfaces/patient.interface';
import { Views } from 'src/app/pages/views';
import { LoginService } from 'src/app/services/login.service';
import { PatientService } from 'src/app/services/patient.service';
import { cpfValidator } from 'src/app/util/cpf-validator';

@Component({
  selector: 'app-ui-add-patient',
  templateUrl: './ui-add-patient.component.html',
  styleUrls: ['./ui-add-patient.component.scss']
})
export class UiAddPatientComponent extends Views implements OnInit{

  constructor(
    public dialogRef: MatDialogRef<UiAddPatientComponent>,
    private fb:UntypedFormBuilder,
    private loginService:LoginService,
    private patientService:PatientService,
  ) {
    super()
  }

  saving:boolean = false;

  ngOnInit(): void {
    this.campos = {
      id: [],
      isDeleted: [],
      cpf: [Validators.required, cpfValidator()],
      name: [Validators.required],
      birthdate: [Validators.required],
      mobile: [Validators.required],
      email: [Validators.required],
    }

    this.initForm(this.fb)
  }

  onChangeEmail(){
    if(!this.loginService.isEmailPatternValid(this.fg.controls.email.value)) {
      this.fg.controls.email.setErrors({
        invalid: true
      })
    }
  }

  async onChangeCPF(){
    const cpf = this.fg.controls.cpf.value;

    if (!cpf) {
      this.fg.controls.cpf.setErrors({
        required: true
      })
      return;
    }

    if(!this.loginService.isCpfPatternValid(cpf)) {
      this.fg.controls.cpf.setErrors({
        invalid: true
      })
      return;
    }

    const exists = await this.patientService.patientExist(cpf).toPromise();

    if(exists) {
      this.fg.controls.cpf.setErrors({
        alreadyExist: true
      })
      return;
    }
  }
  
  async savePatient():Promise<any> {
    try {
      if(this.fg.invalid) {
        this.loginService.showMessage('Preencha todos os campos corretamente')
        return;
      }

      const patient:Patient = this.fg.getRawValue()
      patient.birthdate = moment.utc(patient.birthdate, "DDMMYYYY").format("YYYY-MM-DD")

      if (patient) {
        this.saving = true;
        const response = await this.patientService.savePatient(patient).toPromise();
        
        if(response){
          this.loginService.showMessage(`Cliente cadastrado com sucesso`, 3000);
          this.dialogRef.close({
            patient: {
              id: response.id,
              name: response.name
            }
          }); 
        } else
          this.loginService.showMessage(`Falha ao cadastrar cliente`, 3000);
        
        this.saving = false;
      }
    } catch (error) {
      let message = 'Falha ao cadastrar cliente'
      
      if(error.error?.message) {
        message = error.error.message
      }

      this.loginService.showMessage(message, 3000)
      this.saving = false;
    }
  }

}
