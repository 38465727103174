<app-loading *ngIf="loading"></app-loading>

<mat-dialog-content *ngIf="!loading">
    <ng-contanier *ngIf="data.hasDefaultPaymentMethod">
        <h2 style="text-align: center; color: #2D9BF0;">Atualização de plano</h2>
        <h3 style="text-align: center;">Utilizar último método de pagamente realizado?</h3>
        <div class="confirmation">
            <button mat-button class="btn-cancel" (click)="samePayment(false)">Não</button>
            <button mat-button class="btn-green" (click)="samePayment(true)">Sim</button>
        </div>
    </ng-contanier>
    <form [formGroup]="fg" *ngIf="!data.hasDefaultPaymentMethod">
        <div class="row">
            <div class="col-12 loginForm">
                <mat-form-field appearance="outline" class="col-12">
                <mat-label>Nome do titular</mat-label>
                <input
                    matInput
                    type="text"
                    formControlName="name"
                    oninput="this.value = this.value.toUpperCase()"
                />
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <mat-label>Número do cartão</mat-label>
                <input
                    matInput
                    type="text"
                    formControlName="cardNumber"
                    maxlength="19"
                    (keypress)="onlyNumbers($event)"
                    (input)="setSpaceCreditCard($event)"
                    (change)="validateCardFlag($event)"
                />
                <mat-error *ngIf="fg.controls.cardNumber.hasError('invalid')">
                    <strong>Número de cartão inválido</strong>
                </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <mat-label>Vencimento</mat-label>
                <input
                    matInput
                    type="text"
                    formControlName="dueDate"
                    (input)="setDivisionDueDate($event)"
                    maxlength="5"
                />
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <mat-label>CVV</mat-label>
                    <input
                        matInput
                        type="text"
                        (keypress)="onlyNumbers($event)"
                        formControlName="cvv"
                        maxlength="4"
                    />
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <mat-label>Parcelas</mat-label>
                    <mat-select
                        formControlName="installments"
                        required
                    >
                        <mat-option *ngFor="let installment of installments" [value]="installment">
                        {{ installment.description}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="actionBtn">
            <button mat-button class="btn-green" (click)="sign()">Assinar agora</button>
        </div>
    </form>
</mat-dialog-content>
