<app-ui-main-menu>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Assinatura</mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>
    <app-loading *ngIf="loading"></app-loading>
    <mat-card-content *ngIf="!loading">
      <h1>Escolha seu Plano</h1>
       <div class="additionalUsersDiv" *ngIf="signature?.identifier == signaturePlansNames.PLUS" >
         <form [formGroup]="fg" class="additionalUsers">
           <h2 class="planName">Usuário adicional (Plano Plus)</h2>
           <h2 class="planName">R$ {{(additionalUsersInfo.value/100).toFixed(2)}}</h2>
           <div class="row" class="additionalUserInfo">
             <div class="col-12">
                 <mat-form-field appearance="outline" class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                     <mat-label>Usuários adicionais</mat-label>
                     <input
                         matInput
                         placeholder="Quantidade"
                         type="number"
                         formControlName="quantity"
                     />
                 </mat-form-field>
                 <mat-form-field appearance="outline" class="col-xl-6 col-lg-6 col-md-6 col-sm-12 totalValue">
                     <mat-label>Valor total</mat-label>
                     <input
                         matInput
                         formControlName="value"
                         mask="separator.2"
                         prefix="R$ "
                         thousandSeparator=","
                         decimalMarker=","
                         readonly
                     />
                 </mat-form-field>
             </div>
             <button mat-button class="additionalUserBtn" (click)="setUsers()">Contratar agora</button>
           </div>
         </form>
       </div>
      <div class="planSchema">
        <div class="mobileBorder" *ngFor="let plan of signaturePlans">
          <div class="title">
            <h2 class="planName">{{plan.name}}</h2>
            <h2 [ngClass]="{
              'value': true,
              'freeValue': plan.identifier == 'free',
              'notFreeValue': plan.identifier !== 'free'
            }">{{plan.identifier == 'free'? 'Gratuito' : ('R$ ' + plan.value.replace('.', ','))}} <p class="perMonth" *ngIf="plan.identifier !== 'free'">/ano</p></h2>
          </div>
          <div class="middleInfo">
            <p><fa-icon icon="check"></fa-icon> Agendamento Online</p>
            <p><fa-icon icon="check"></fa-icon> Plataforma de Gestão de Clientes</p>
            <p *ngIf="plan.identifier == 'plus'"><fa-icon icon="check"></fa-icon> Agenda médica exclusiva</p>
            <p *ngIf="plan.identifier !== 'free'"><fa-icon icon="check"></fa-icon> Suporte {{plan.identifier == 'essential'? 'Preferencial' : 'exclusivo'}}</p>
            <p *ngIf="plan.identifier == 'plus'"><fa-icon icon="check"></fa-icon> Prioridade em demandas de urgência</p>
            <p *ngIf="plan.maxUsers"><fa-icon icon="check"></fa-icon> {{plan.maxUsers}} Usuário(s)</p>
            <p *ngIf="plan.schedulePrice" class="scheduleValue"><fa-icon icon="check"></fa-icon> Valor da consulta R$ {{plan.schedulePrice}}</p>
          </div>
          <div class="signatureAction">
            <button mat-button class="planBtn" (click)="openIntercomChat()" *ngIf="!(signature?.identifier == plan.identifier) && plan.identifier !== 'free' && (signature?.identifier)">Fale com Consultor</button>
            <button mat-button class="planBtn" (click)="onChoosePlan(plan)" *ngIf="plan.identifier == 'free' && signature?.identifier !== 'free'">Assinar</button>
            <p *ngIf="signature?.identifier !== plan.identifier && plan.maxUsers" class="anualPlanTxt">Plano anual</p>
            <p *ngIf="!plan.maxUsers" class="anualPlanTxt">Plano exclusivo</p>
            <h3 *ngIf="signature?.identifier == plan.identifier || (!signature?.identifier && plan.identifier === 'plus')" class="signedText">ASSINADO</h3>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</app-ui-main-menu>
