import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';
import { AccountInfo, LocalStorageUtil } from '../util/local-storage-util';

import * as moment from 'moment';
import { Injectable } from '@angular/core';

type Field = {
  [key: string]: UntypedFormControl;
};

type FieldConfig = {
  [key: string]: ValidatorFn[];
};

@Injectable({
  providedIn: 'root',
})
export class Views {
  account: AccountInfo;
  campos: FieldConfig;
  loading = true;
  fg: UntypedFormGroup;
  tinyClass = '';
  logo: string;
  isOnIframe = false;

  constructor() {
    this.account = LocalStorageUtil.getAccountInfo();
  }

  initForm(fb: UntypedFormBuilder) {
    this.fg = new UntypedFormGroup(this.createFields(this.campos, fb));
  }

  createFields(campos: FieldConfig, fb: UntypedFormBuilder): Field {
    const fields: Field = {};

    for (const key in campos) {
      if (campos.hasOwnProperty(key)) {
        const c = campos[key];
        fields[key] = fb.control(null, c);
      }
    }

    return fields;
  }

  validateAll() {
    Object.keys(this.fg.controls).forEach((field) => {
      const control = this.fg.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  // Método padrão para carregar primeiro nivel de formulários simples
  loadFormValues(response): void {
    for (const e in response) {
      if (this.campos.hasOwnProperty(e)) {
        this.fg.controls[e].setValue(response[e]);
      }
    }
  }

  protected dateValidator(control: AbstractControl) {
    if (!moment(control.value, 'DD/MM/YYYY').isValid()) {
      return { validator: true };
    }

    return null;
  }

  nameValidator(control: AbstractControl) {
    const val = control.value && control.value.trim();
    const reg = /^([a-zàèìòùáéíóúâêîôûãñõäëïöüç]+[\s]{1}[a-zàèìòùáéíóúâêîôûãñõäëïöüç]+)/gi;
    return val && !reg.test(val) && { validator: true };
  }
}
