import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ChangeSignatureRequestDTO } from '../dto/change-signature.dto';

@Injectable({
  providedIn: 'root'
})
export class ChangePlanService {
  private readonly apiUrl = environment.api;

  constructor(
    private readonly http: HttpClient,
  ) {}

  changePlan(data: ChangeSignatureRequestDTO): Observable<any> {
    return this.http.put(`${this.apiUrl}/admin/change-signature`, data);
  }

}
