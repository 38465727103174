
<div class="box-select">
    <mat-form-field appearance="outline">
      <mat-label>Filtrar por cliente</mat-label>
      <input
        matInput
        autocomplete="off"
        type="search"
        [formControl]="busca"
        placeholder="Busque um cliente pelo nome"
        (focus)="exibir = true"
        />
        <!-- (change)="onBlur()" -->
      <div matSuffix *ngIf="loading">
        <mat-spinner [diameter]="25"></mat-spinner>
      </div>
    </mat-form-field>

    <mat-action-list
      [ngClass]="{ aberto: exibir, fechado: !exibir }"
    >
      <button *ngFor="let t of _data" mat-list-item (mousedown)="selecionar(t)">
        <span>
          {{ t.name }}
        </span>

      </button>
      <mat-list-item *ngIf="_data.length == 0 && !loading" role="listitem">
        Digite um nome para procurar o cliente
      </mat-list-item>
    </mat-action-list>
</div>
