import { Injectable } from '@angular/core';
import { ApiServiceService } from './api-service.service';
import { Observable } from 'rxjs';
import { DefaultResponse } from '../pages/base.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IndicationsService {

  constructor(
    private apiService: ApiServiceService,
    private http: HttpClient
  ) { }

  public getAccountIndications(filters: { type: 'schedule' | 'attendance', patientId?: number, status?: string}): Observable<DefaultResponse<any>>{
    const params = [];
    for(const key in filters)
        if(!!filters[key])
          params.push(`${key}=${filters[key]}`)

    return this.apiService.get('/indications'+`${params.length > 0? ('?' + params.join('&')) : ''}`)
  }

  public getById(indicationId: number): Observable<DefaultResponse<any>>{
    return this.apiService.get(`/indications/${indicationId}`)
  }

  public save(indication: FormData): Observable<DefaultResponse<any>>{
    return this.apiService.post(`/indications`, indication)
  }

  public uploadFiles(formData: FormData, indicationId: number): Observable<DefaultResponse<any>>{
    return this.apiService.post(`/indications/${indicationId}/upload`, formData)
  }

  public generateSSOLink(patientId: number): Observable<{url : string}> {
    return this.apiService.post(`/indications/communicare/generate-sso-link/${patientId}`)
  }

  public cancelScheduleOnCommunicare(indicationId: number, scheduleId: number): Observable<boolean> {
    return this.apiService.delete(
      `/indications/communicare/cancel-schedule/${indicationId}/${scheduleId}`
    )
  }

  public checkAvailabilityForScheduling(patientId: number): Observable<boolean> {
    return this.apiService.get(`/indications/check-availability/${patientId}`)
  }
}
