import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
  ) { 
    this.token = this.activatedRoute.snapshot.params['token'];
  }

  token = ''

  step = 1;
  public logo: string = 'assets/logoRegister.png';

  ngOnInit(): void {
    this.checkToken()
  }

  checkToken(){

    this.loginService.checkToken(this.token).subscribe(
      res=>{
        if(res.status){
          this.step = 2;
          setTimeout(()=>{
            this.router.navigate([`/login/${this.token}`]);
          },4000)
        }else{
          this.step = 3
          this.loginService.showMessage('Erro no link. Aguarde, estamos redirecionando...', 6000);
          setTimeout(()=>{
            this.router.navigate(['/login']);
          },4000)
        }
      },
      error=>{
        this.loginService.showMessage('Aguarde, estamos redirecionando...', 6000);
        setTimeout(()=>{
          this.router.navigate(['/login']);
        },4000)
      }
    )
  }
}
