export class PasswordStrength {
  public dica: string;
  public iniciado = false;
  public dicas = [];

  constructor(
    public valor: number = 0,
    public cor: string = 'primary',
    public visivel: boolean = false,
    public visivelDicas: boolean = false,
    public titulo: string = 'A senha precisa conter:'
  ) {}

  private checkRepetition(pLen: number, str: string): string {
    let res = '';
    for (let i = 0; i < str.length; i++) {
      let repeated = true;
      let j: number;

      for (j = 0; j < pLen && j + i + pLen < str.length; j++) {
        repeated = repeated && str.charAt(j + i) === str.charAt(j + i + pLen);
      }

      if (j < pLen) {
        repeated = false;
      }

      if (repeated) {
        i += pLen - 1;
        repeated = false;
      } else {
        res += str.charAt(i);
      }
    }
    return res;
  }

  public checkForcaSenha(password: string): void {
    const focarSenha = 0;
    let score = 0;
    this.visivel = true;
    this.visivelDicas = false;
    this.iniciado = true;
    this.dicas = [];

    if (password === '' || password === undefined) {
      this.valor = 1;
      this.cor = 'warn';
      this.visivel = true;
      this.visivelDicas = false;
    }

    if (password.length < 8) {
      this.valor = password.length * 4;
      this.cor = 'warn';
      this.visivel = true;
      this.visivelDicas = true;
      this.dicas.push('Ao menos 8 caracteres');
    }

    if (!password.match(/([a-z]|[A-Z])/)) {
      this.valor = password.length * 4;
      this.cor = 'warn';
      this.visivelDicas = true;
      this.dicas.push('Ao menos uma letra maiúscula e/ou minúscula');
    }

    if (!password.match(/[0-9]/)) {
      this.valor = password.length * 4;
      this.cor = 'warn';
      this.visivelDicas = true;
      this.dicas.push('Ao menos um número');
    }

    if (!password.match(/[!,@,#,$,%,^,&,*,?,_,~]/)) {
      this.valor = password.length * 4;
      this.cor = 'warn';
      this.visivelDicas = true;
      this.dicas.push('Ao menos um caracter especial.');
    }

    if (this.valor < 40) {
      this.cor = 'warn';
      this.dica = 'Força: Fraca';
    } else if (this.valor < 50) {
      this.cor = 'warn';
      this.dica = 'Força: Média';
    } else if (this.valor < 50) {
      this.cor = 'primary';
      this.dica = 'Força: Média';
    } else if (this.valor < 70) {
      this.cor = 'primary';
      this.dica = 'Força: Boa';
    } else if (this.valor < 85) {
      this.cor = 'primary';
      this.dica = 'Força: Muito Boa';
    } else {
      this.cor = 'primary';
      this.dica = 'Força: Excelente';
    }

    if (!this.visivelDicas) {
      score += password.length * 4;
      score += (this.checkRepetition(1, password).length - password.length) * 1;
      score += (this.checkRepetition(2, password).length - password.length) * 1;
      score += (this.checkRepetition(3, password).length - password.length) * 1;
      score += (this.checkRepetition(4, password).length - password.length) * 1;
      score += (this.checkRepetition(5, password).length - password.length) * 1;
      score += (this.checkRepetition(6, password).length - password.length) * 1;
      score += (this.checkRepetition(7, password).length - password.length) * 1;
      score += (this.checkRepetition(8, password).length - password.length) * 1;

      // password has 3 numbers
      if (password.match(/(.*[0-9].*[0-9].*[0-9])/)) {
        score += 5;
      }

      // password has 2 symbols
      if (
        password.match(/(.*[!,@,#,$,%,^,&,*,?,_,~].*[!,@,#,$,%,^,&,*,?,_,~])/)
      ) {
        score += 5;
      }

      // password has Upper and Lower chars
      if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
        score += 10;
      }

      // password has number and chars
      if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) {
        score += 25;
      }
      //
      // password has number and symbol
      if (
        password.match(/([!,@,#,$,%,^,&,*,?,_,~])/) &&
        password.match(/([0-9])/)
      ) {
        score += 25;
      }

      // password has char and symbol
      if (
        password.match(/([!,@,#,$,%,^,&,*,?,_,~])/) &&
        password.match(/([a-zA-Z])/)
      ) {
        score += 25;
      }

      // password is just a numbers or chars
      if (password.match(/^\w+$/) || password.match(/^\d+$/)) {
        score -= 10;
      }

      // verifying 0 < score < 100
      if (score < 0) {
        score = 0;
      }
      if (score > 100) {
        score = 100;
      }

      this.valor = score;

      if (score < 40) {
        this.cor = 'warn';
        this.visivel = true;
        this.dica = 'Força: Fraca';
        return;
      }

      if (score < 50) {
        this.cor = 'warn';
        this.visivel = true;
        this.dica = 'Força: Média';
        return;
      }

      if (score < 70) {
        this.cor = 'primary';
        this.visivel = true;
        this.dica = 'Força: Boa';
        return;
      }

      if (score < 85) {
        this.cor = 'primary';
        this.visivel = true;
        this.dica = 'Força: Muito Boa';
        return;
      }

      this.cor = 'primary';
      this.visivel = true;
      this.dica = 'Força: Excelente';
      return;
    }
  }
}
