import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TakeAccountRequestDTO, TakeAccountResponseDTO } from '../dto/take-account.dto';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/interfaces/user.interface';

@Injectable({
  providedIn: 'root'
})
export class TakeAccountService {
  private readonly apiUrl = environment.api;

  constructor(
    private readonly http: HttpClient,
  ) {}

  takeAccountAccess(data: TakeAccountRequestDTO): Observable<TakeAccountResponseDTO> {
    return this.http.post<TakeAccountResponseDTO>(`${this.apiUrl}/admin/take-account`, data);
  }
}
