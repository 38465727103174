import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-ui-confirmation-dialog',
  templateUrl: './ui-confirmation-dialog.component.html',
  styleUrls: ['./ui-confirmation-dialog.component.scss']
})
export class UiConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<UiConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialog
  ) {
    if (!data.cancel) {
      data.cancel = 'Cancelar';
    }

    if (!data.confirm) {
      data.confirm = 'Confirmar';
    }
  }

  action(): void {
    this.dialogRef.close({ confirm: true });
  }
}

export interface ConfirmationDialog {
  cancel?: string;
  confirm?: string;
  title: string;
  question: string;
}
