<app-ui-main-menu [userRegistration]="accountTaken">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Assumir Conta</mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content >
      <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-6">
            <mat-form-field appearance="outline">
              <mat-label>CPF ou E-mail</mat-label>
              <input
                matInput
                formControlName="cpfOrEmail"
              />
            </mat-form-field>

            <div class="button-container">
              <button
                mat-button
                class="btn-green"
              >
                Entrar
              </button>
            </div>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</app-ui-main-menu>
