import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login.service';
import { environment } from 'src/environments/environment';

declare var Iugu: Iugu;

@Component({
  selector: 'app-card-dialog',
  templateUrl: './card-dialog.component.html',
  styleUrls: ['./card-dialog.component.scss']
})
export class CardDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private loginService: LoginService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    Iugu.setAccountID(environment.iuguId);
    Iugu.setTestMode(!environment.production);
   }

  fg = new UntypedFormGroup({
    name: new UntypedFormControl(null,[Validators.required]),
    cardNumber: new UntypedFormControl(null,[Validators.required]),
    dueDate: new UntypedFormControl(null,[Validators.required]),
    cvv: new UntypedFormControl(null,[Validators.required]),
    installments: new UntypedFormControl(null,[Validators.required]),
  })

  installments = [];

  loading = false;

  brand:string = '';

  ngOnInit(): void {
    this.generateInstallments()
  }

  generateInstallments(){
    this.installments = Array.from({length: 12}, (_, i)=>{
      const installmentNumber = i + 1;
      const installmentValue = ( Number(this.data.plan.value) /installmentNumber ).toFixed(2).replace('.', ',')
      return {
        description: `${installmentNumber} x R$ ${installmentValue}`,
        value: installmentNumber
      }
    })
  }

  onlyNumbers(e:KeyboardEvent){
    const k = e.charCode;
    return k >= 48 && k <= 57;
  }

  async validateCardFlag(e:any){
    const cardNumber = e.target.value.replace(/\D/g, "")
    const isValidaCard = await this.luhnValidation(cardNumber);

    if(!isValidaCard){
      this.fg.controls.cardNumber.setErrors({ 'invalid': true})
      return;
    }
  }

  async setSpaceCreditCard(e:InputEvent){
    let cardNumber = this.fg.controls.cardNumber.value;
    cardNumber = cardNumber.replace(/\D/g, "");
    this.fg.controls.cardNumber.setValue(cardNumber.match(/\d{1,4}/g).join(' '));
  }

  //Verifica se um número de cartão é válido usando o algoritmo de Luhn
  async luhnValidation (cardNumber: string): Promise<boolean> {
    if (!cardNumber.length) {
        return;
    }

    // Remove o último dígito;
    const lastDigit = Number(cardNumber[cardNumber.length - 1]);

    // inverte os números do cartão
    const reverseCardNumber = cardNumber.slice(0, cardNumber.length - 1).split('').reverse().map(x => Number(x));
    let sum = 0;

    // Múltiplica por 2 cada dígito em posição ímpar
    // Depois subtrai 9 se o resultado da multiplicação for > 9
    for (let i = 0; i <= reverseCardNumber.length - 1; i += 2) {
        reverseCardNumber[i] = reverseCardNumber[i] * 2;
        if (reverseCardNumber[i] > 9) {
            reverseCardNumber[i] = reverseCardNumber[i] - 9;
        }
    }

    // Soma todos os valores obtidos no loop anterior
    sum = reverseCardNumber.reduce((acc, currValue) => (acc + currValue), 0);

    // Calcula o módulo de 10 da soma anterior com o último digito removido na primeira etapa
    // Se o resultado for 0, ele será um cartão válido
    return ((sum + lastDigit) % 10 === 0);
  }

  setDivisionDueDate(e:InputEvent){
    const dueDate = this.fg.controls.dueDate.value;
    if(e.data === null){
      if(dueDate[dueDate.length - 1] === '/')
        this.fg.controls.dueDate.setValue(dueDate.replace('/', ''))

      return;
    }

    if(dueDate.length === 2){
      this.fg.controls.dueDate.setValue(dueDate + '/');
    }else if(dueDate.length === 3 && dueDate[2] !== '/'){
      const dividedDueDate = dueDate.slice(0, 2);
      this.fg.controls.dueDate.setValue(dividedDueDate + '/' + dueDate[2]);
    }
  }

  private async validateCreditCard() {

    if (!this.fg.valid) {
      this.loginService.showMessage('Preencha os campos corretamente', 3000)
      return;
    }

    if (
      !Iugu.utils.validateCreditCardNumber(this.fg.controls.cardNumber.value)
    ) {
      this.loginService.showMessage(`Número do cartão de crédito inválido, verifique, por favor`, 5000);
      return;
    }


    const date = this.fg.controls.dueDate.value.split('/');
    if (!Iugu.utils.validateExpiration(date[0], date[1])) {
      this.loginService.showMessage(`Cartão expirado, verifique, por favor`, 5000);
      return;
    }

    const bandeira = Iugu.utils.getBrandByCreditCardNumber(
      this.fg.controls.cvv.value
    );
    if (
      bandeira && !Iugu.utils.validateCVV(this.fg.controls.cvv.value, bandeira)
    ) {
      this.loginService.showMessage(`CVV incorreto, verifique, por favor`, 5000);
      return;
    }

    const nameSplitted: string[] = this.fg.controls.name.value.split(' ');
    if (nameSplitted.length < 2) {
      this.loginService.showMessage(`Digite o nome completo`, 5000);
      return;
    }

    this.brand = bandeira;
  }

  sign(){
    this.validateCreditCard();

    const name = this.fg.value.name.split(' ');
    const date = this.fg.value.dueDate.split('/');
    const cardInfo = {
      number: this.fg.value.cardNumber.split(' ').join(''),
      verification_value: this.fg.value.cvv,
      first_name: name[0],
      last_name: name.slice(1).join(' '),
      month: date[0],
      year: date[1]
    };
    Iugu.createPaymentToken(cardInfo, (res) => {
      if ('errors' in res) {
        console.log(res.errors);
        this.loading = false;
      } else {
        this.dialogRef.close({token: res.id, installments: this.fg.value.installments.value});
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  samePayment(confirm: boolean){
    if(confirm)
      this.dialogRef.close({token: null});
    else
      this.data.hasDefaultPaymentMethod = false;
  }

}
