<div class="wrapper">
  <app-loading *ngIf="loading" ></app-loading>
    <div class="loginCard" align="center" *ngIf="!loading">  
      <mat-card style="padding: 30px;">
        <form [formGroup]="fg" [ngSwitch]="viewType">
          <div class="row" >
            <div class="col-12">
              <img [src]="logo" class="imgLogo"/>
            </div>
    
            <ng-container *ngSwitchCase="'forgot'">
              <div class="col-12">
                  <mat-form-field appearance="outline">
                      <mat-label>CPF</mat-label>
                      <input
                        matInput
                        formControlName="cpf"
                        [specialCharacters]="['/', '.', '-']"
                        [mask]="'000.000.000-00'"
                        (change)="onChangeCPF()"
                        required
                      />
                      <mat-error *ngIf="fg.hasError('invalid', ['cpf'])">
                        CPF inválido
                      </mat-error>
                    </mat-form-field>
              </div>
      
              <div class="col-12" align="left">
                <a *ngIf="!isOnIframe"
                  routerLink="../login"
                  style="font-weight: bold; text-decoration: none; font-size: 16px;"
                  class="blue"
                  ><u>Voltar</u></a
                >
              </div>
            </ng-container>
    
            <ng-container *ngSwitchCase="'reset'">

                <div class="invalidToken" *ngIf="!validToken">
                  <fa-icon icon="times-circle"></fa-icon>
                  <h2>Link inválido! Envie um novo email para continuar</h2>
                </div>
                <ng-container *ngIf="validToken">
                  
                  <div class="col-12">
                    <mat-form-field appearance="outline">
                      <mat-label>Senha</mat-label>
                      <input matInput type="password" formControlName="newPass" />
                    </mat-form-field>
                  </div>
    
                  <div class="col-12">
                  <mat-form-field appearance="outline">
                      <mat-label>Confirme a senha</mat-label>
                      <input matInput type="password" formControlName="confirmNewPass" />
                    </mat-form-field>
                  </div>
                </ng-container>
              </ng-container>
    
            <div class="col-12 p-margin-top-3" *ngIf="validToken">
              <button *ngIf="!isOnIframe" mat-button class="btn-secondary btn-large" (click)="onChooseAction()">
                <span *ngIf="!loading; else loadingLogin">
                  Redefinir senha
                </span>
    
                <ng-template #loadingLogin>
                  <mat-spinner [diameter]="30" color="primary"> </mat-spinner>
                </ng-template>
              </button>
            </div>
          </div>
        </form>
      </mat-card>
  
    </div>
  </div>
  
