export const MSG = {
  LOGIN: {
    LOGIN_EXPIRED: 'Faça o login novamente para continuar',
  },
  REGISTER_EMAIL_SENT_SUCCESSFULLY:
    'E-mail de ativação de cadastro reenviado com sucesso!',
  REGISTER_EMAIL_SENT_ERROR: 'Erro ao enviar e-mail de ativação de cadastro!',
  PROFILE: {
    INCORRECT_FIELDS: 'Por favor preencha todos os campos corretamente.',
    UPDATED_SUCESSS: 'Informações do paciente atualizadas com sucesso!',
    UPDATED_ERROR: 'Erro ao atualizar informações do paciente.',
    INCORRECT_HEALTHPLAN_FIELDS: 'Verifique os campos informados.',
    EMPTY_HEALTHPLAN_SELECT: 'Preencha o convênio para visualizar os planos.',
    SCHEDULER_UPDATED: 'Informações do agendador atualizadas com sucesso!',
    SCHEDULER_UPDATED_ERROR: 'Erro ao atualizar informações do agendador',
  },
  ADD_SCHEDULE: {
    CANT_ADD_SCHEDULE:
      'Contate o administrador da conta para adicionar um convênio!',
  },
};