export const indicationStatus = [
    'Aguardando agendamento',
    'Agendado',
    'Aguardando reagendamento',
    'Aguardando pagamento',
    'Ausência na consulta',
    'Reagendado'
]

export enum IndicationsAttendanceStatus{
    ATTEND = 'Atendido'
}