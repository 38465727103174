import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AccountSignature } from 'src/app/pages/signature/signature.interface';
import { LoginService } from 'src/app/services/login.service';
import { SignatureService } from 'src/app/services/signature.service';
import { LocalStorageUtil } from 'src/app/util/local-storage-util';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanLoad, CanActivate {
  private token: string;
  private accountSignature: AccountSignature;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private signatureService: SignatureService,
  ) { }

  canActivate(
    activeRoute: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot
  ): Observable<boolean> {
    this.token = LocalStorageUtil.getToken();

    if (!!this.token) {
      return this.checkToken();
    }

    this.router.navigate(['/login']);
    return of(false);
  }

  canLoad(route: Route): Observable<boolean> {
    this.token = LocalStorageUtil.getToken()
    if (!!this.token) {
      return this.checkToken();
    }

    this.router.navigate(['/login']);
    return of(false);
  }

  private checkToken(): Observable<boolean> {
    const checkActiveSignature = !this.signatureService.accountSignatureInfo
    return this.loginService.checkToken(this.token, checkActiveSignature).pipe(
      map(res => {

        if (res?.path !== '') {
          //this.router.navigate([path]);
        }

        if(res?.data){
          this.signatureService.accountSignatureInfo = res.data
        }

        const result = !!res && this.signatureService.accountSignatureInfo?.active;

        if(!result)
          this.loginService.showMessage('Complete a assinatura para utilizar os módulos do sistema', 5000);

        return result;
      }),
      catchError(error => {
        this.loginService.showMessage('Token inválido', 6000);
        this.router.navigate(['/login']);
        return of(false)
      })
    )
  }
}
