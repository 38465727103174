<app-ui-main-menu>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Troca de Plano</mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content *ngIf="!loading">
      <div class="loading-screen" *ngIf="loading">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>

      <form class="form" *ngIf="!loading" [formGroup]="form">
        <div class="row">
          <div class="col-6">
            <mat-form-field appearance="outline">
              <mat-label>ID do Cliente Iugu</mat-label>
              <input
                matInput
                formControlName="iuguId"
                required
              />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <mat-form-field appearance="outline">
              <mat-label>ID da Assinatura Iugu</mat-label>
              <input
                matInput
                formControlName="iuguSignatureId"
                required
              />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <mat-form-field appearance="outline">
              <mat-label>Limite de usuários</mat-label>
              <input
                type="number"
                matInput
                formControlName="maxUsers"
                required
              />
            </mat-form-field>
          </div>
        </div>
      </form>

      <mat-divider></mat-divider>

      <div *ngIf="!loading" class="action-buttons">
        <button
          mat-button
          class="btn-green"
          (click)="submit()"
        >
          <fa-icon icon="check"></fa-icon>
          Salvar
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</app-ui-main-menu>
