<app-ui-main-menu>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Indicações</mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content>

      <div  class="actions col-12">
          <form [formGroup]="filters" class="col-12 filters">

            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
              <app-ui-select-patient
                name="paciente"
                (change)="onChangePaciente($event)"
                style="margin-left: 0"
              >
              </app-ui-select-patient>
            </div>

            <mat-form-field  *ngIf="showFilterStatus" appearance="outline" class="col-xl-4 col-lg-5 col-md-4 col-sm-8">
              <mat-label>Filtrar por Status</mat-label>
              <mat-select formControlName="status" (selectionChange)="onChangeStatusFilter()">
                <mat-option *ngFor="let status of statusType" [value]="status">
                  {{ status }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div class=" col-xl-2 col-lg-2 col-md-2 col-sm-12 addIndications">
              <button mat-flat-button class="btn-primary" (click)="goToSaveIndication()" >Fazer agendamento</button>
            </div>
          </form>
      </div>

      <mat-tab-group (selectedTabChange)="onTabChange($event)">
        <mat-tab label="Em andamento">
          <ng-template matTabContent>
            <app-indications-list
              [tab]="'schedule'"
              [patient]="filters.value.patient"
              [status]="filters.value.status"
            ></app-indications-list>
          </ng-template>
        </mat-tab>

        <mat-tab label="Concluídos">
          <ng-template matTabContent>
            <app-indications-list
              [tab]="'attendance'"
              [patient]="filters.value.patient"
              [status]="filters.value.status"
            ></app-indications-list>
          </ng-template>
        </mat-tab>

      </mat-tab-group>

    </mat-card-content>
  </mat-card>
</app-ui-main-menu>

