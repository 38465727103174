import { Injectable } from '@angular/core';
import { ApiServiceService } from './api-service.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountSignature, SignaturePlans, SignaturePlansNames } from '../pages/signature/signature.interface';
import { LocalStorageUtil } from '../util/local-storage-util';

@Injectable({
  providedIn: 'root'
})
export class SignatureService {

  constructor(private apiService: ApiServiceService) { }

  accountSignatureInfo: AccountSignature = null;

  getAccountSignature(): Observable<{
    data: AccountSignature
  }>{
    try{
      return this.apiService.get(`/subscription`).pipe(
        map(
          (signature: any) =>{
            if(signature) {
              this.accountSignatureInfo = {
                active: signature.data?.active,
                suspended: signature.data?.suspended,
                identifier: signature.data?.identifier
              }

              LocalStorageUtil.setSignature({
                active: signature.data?.active,
                suspended: signature.data?.suspended,
                identifier: signature.data?.identifier
              })

              return signature;
            }else return null;
          }
        )
      );

    }catch(error){
      return;
    }
  }

  getPlans(currentPlan: SignaturePlansNames = null): Observable<any>{
    if(currentPlan != null) {
      return this.apiService.get(`/subscription/plans?currentPlan=${currentPlan}`);
    }

    return this.apiService.get(`/subscription/plans`);
  }

  getAccountQuantity(): Observable<any>{
    return this.apiService.get(`/subscription/users-quantity`);
  }

  createSignature(plan: SignaturePlans, cardToken = null, installments = 1){
    return this.apiService.post('/subscription/create', { planIdentifier: plan.identifier, maxUsers: plan.maxUsers, cardToken, installments });
  }

  changeSubscription(plan: SignaturePlans, cardToken = null, installments = 1): Observable<any> {
    return this.apiService.post(`/subscription/change-plan`, { newPlanIdentifier: plan.identifier, maxUsers: plan.maxUsers, cardToken, installments });
  }

  addUsersToSignature(data: any): Observable<any> {
    return this.apiService.post(`/subscription/add-users`, data);
  }

  getAccountInvoices(data: any): Observable<any> {
    return this.apiService.post(`/subscription/invoices`, data);
  }
}
