
<div class="wrapper">
    <div class="loginCard" align="center">  
      <mat-card style="padding: 30px;">

        <div class="row" >
            <div class="col-12">
                <img [src]="logo" class="imgLogo"/>
            </div>
            <div class="validationSteps col-12">
                <h1 *ngIf="step == 1">Validando link, aguarde</h1>
                <h1 *ngIf="step == 2">Link Validado! Redirecionado...</h1>
                <h1 *ngIf="step == 3">Link inválido! Redirecionado...</h1>
                <app-loading></app-loading>
            </div>
        
        </div>

      </mat-card>
  
</div>
</div>
  

