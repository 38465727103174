import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login.service';
import { UserComponent } from './user/user.component';
import { UsersService } from 'src/app/services/users.service';
import { SignatureService } from 'src/app/services/signature.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent {

  constructor(
    private dialog: MatDialog,
    private loginService: LoginService,
    private userService: UsersService,
    private signatureService: SignatureService,
  ) { 

    signatureService.getAccountQuantity().subscribe(
      res=>{
        if(res?.status)
          this.accountQuantity = res.data;
      },
      error=>{
        this.loginService.showMessage('Erro ao pegar informações da conta', 3000);
      }
    )

  }

  accountQuantity = {
    quantityUsers: 1,
    maxUsers: 1
  };

  onSetUser(id: number | 'novo' = 'novo'){
    if(this.accountQuantity.quantityUsers < this.accountQuantity.maxUsers){
      const dialogRef = this.dialog.open(UserComponent,{
        disableClose: true,
        data: {
          id
        }
      })
  
      dialogRef.afterClosed().subscribe(() => {})
    }else
      this.loginService.showMessage('Quantidade de usuários ativos atingida. Atualize seu plano para continuar', 4500);
  }

}
