import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { PasswordStrength } from 'src/app/util/password-strength.class';
import { UsersService } from 'src/app/services/users.service';
import { Router } from '@angular/router';
import { RegisterForm } from './models/register-form.interface';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  loading = false;
  form: FormGroup<RegisterForm>;
  typePassword = 'password'
  passStrength = new PasswordStrength()
  tips = ''
  isPlusPlan = false;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private usersService: UsersService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    if (this.router.url.includes('plus-plan')) {
      this.isPlusPlan = true;
    }

    this.form = this.formBuilder.group({
      cpf: ['', [Validators.required]],
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      mobile: ['', [Validators.required]],
      password: ['', [Validators.required]],
      term: ['', [Validators.required]],
      isPlusPlan: [this.isPlusPlan, [Validators.required]],
    });
  }

  onChangePassword(): void {
    let password = this.form.controls['password'].value || '';
    this.passStrength.checkForcaSenha(password);
    this.tips = this.passStrength.dicas.join('<br/>');

    if(this.passStrength.dicas.length > 0) {
      this.form.controls.password.setErrors({
        invalid: true
      })
    }
  }

  onChangeEmail(){
    if(!this.loginService.isEmailPatternValid(this.form.controls.email.value)) {
      this.form.controls.email.setErrors({
        invalid: true
      })
    }
  }

  async onChangeCPF(){
    if(!this.loginService.isCpfPatternValid(this.form.controls.cpf.value)) {
      this.form.controls.cpf.setErrors({
        invalid: true
      })
    }

    if(await this.usersService.userExist(this.form.controls.cpf.value).toPromise()) {
      this.form.controls.cpf.setErrors({
        alreadyExist: true
      })
    }
  }

  onSubmit(){
    if(!this.form.valid) {
      this.loginService.notification$.next({
        message: 'Preencha todos os campos corretamente',
      })

      return;
    }

    this.loading = true

    this.loginService.showMessage('Criando nova conta, aguarde...')

    this.usersService.newAccount(this.form.getRawValue()).subscribe(r => {
      this.loading = false

      this.loginService.showMessage('Conta criada com sucesso! Faça o login')

      this.router.navigate(['/login']);
    }, (error) => {
      this.loading = false

      this.loginService.showMessage('Erro ao criar conta, por favor, tente novamente!')
    })
  }

}
