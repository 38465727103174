import {
  ILoginRequest,
  ILoginResponse,
} from './../pages/login/login.interface';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiServiceService } from './api-service.service';
import { Router } from '@angular/router';
import { AccountInfo, LocalStorageUtil } from '../util/local-storage-util';
import { DefaultResponse } from '../pages/base.interface';
import { SignatureService } from './signature.service';

import Intercom, { show, shutdown } from '@intercom/messenger-js-sdk';
import { environment } from 'src/environments/environment';

declare global {
  interface Window {
    clarity: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private apiService: ApiServiceService, private signatureService:SignatureService,private router: Router) { }

  isSentryLoaded = false;
  intercomChatIsLoaded = false;

  public notification$: Subject<{
    // Texto da mensagem
    message: string;
    // Duração da mensagem
    duration?: number;
    // Conteúdo da mensagem
    error?: any;
  }> = new Subject();

  public showMessage(message: string, duration: number=3000){
    this.notification$.next({
      message,
      duration
    })
  }

  public login(
    params: ILoginRequest
  ): Observable<DefaultResponse<ILoginResponse>> {
    return this.apiService.post(
      '/auth/login' +
        '?id=' +
        params.id +
        '&password=' +
        encodeURIComponent(params.password)
    );
  }

  public logout() {
    this.apiService
      .post('/auth/logout')
      .toPromise()
      .then((resp) => {
        this.signatureService.accountSignatureInfo = null;
        LocalStorageUtil.logout();

        this.router.navigate(['/login'], { queryParamsHandling: 'preserve' });
      });
  }

  isEmailPatternValid(email: any) {
    const emailRegex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/i;
    if (!emailRegex.test(email)) return false;
    return true;
  }

  // Verifica se CPF possui formatação válida
  isCpfPatternValid(cpf: any): boolean {
    if (!cpf) {
      return false;
    }

    if (!cpf || cpf.length < 11) return false;

    let numeros: any;
    let digitos: any;
    let soma: number;
    let i: number;
    let resultado: number;
    let digitos_iguais = 1;

    digitos_iguais = 1;

    if (cpf.length < 11) {
      return false;
    }

    for (i = 0; i < cpf.length - 1; i++) {
      // tslint:disable-next-line:triple-equals
      if (cpf.charAt(i) != cpf.charAt(i + 1)) {
        digitos_iguais = 0;
        break;
      }
    }
    if (!digitos_iguais) {
      numeros = cpf.substring(0, 9);
      digitos = cpf.substring(9);
      soma = 0;
      for (i = 10; i > 1; i--) {
        soma += numeros.charAt(10 - i) * i;
      }

      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      // tslint:disable-next-line:triple-equals
      if (resultado != digitos.charAt(0)) {
        return false;
      }

      numeros = cpf.substring(0, 10);
      soma = 0;
      for (i = 11; i > 1; i--) {
        soma += numeros.charAt(11 - i) * i;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      // tslint:disable-next-line:triple-equals
      if (resultado != digitos.charAt(1)) {
        return false;
      }

      return true;
    } else {
      return false;
    }
  }

  isCnpjPatternValid(cnpj) {
    if (!cnpj) {
        return false;
    }

    // Remove caracteres não numéricos
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj.length !== 14) {
        return false;
    }

    // Verifica se todos os dígitos são iguais
    if (/^(\d)\1+$/.test(cnpj)) {
        return false;
    }

    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    let i;

    // Cálculo do primeiro dígito verificador
    for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) {
            pos = 9;
        }
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) {
        return false;
    }

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    // Cálculo do segundo dígito verificador
    for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) {
            pos = 9;
        }
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) {
        return false;
    }

    return true;
  }

  checkToken(token:string, checkActiveSignature = false){
    return this.apiService.get(`/auth/check?token=${token}&signature=${checkActiveSignature}`);
  }

  public resetPassword(pass:string, token:string):Observable<DefaultResponse<void>>{
    return this.apiService.post('/auth/reset', {pass, token})
  }

  public startIntercom(user: AccountInfo) {
    this.clarityIdentify(user);

    if (!this.intercomChatIsLoaded) {
      Intercom({
        app_id: environment.intercom,
        user_id: user.userId, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
        name: user.userName, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
        email: user.userEmail, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
        created_at: user.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
      });

      this.intercomChatIsLoaded = true;
    }
  }

  public showIntercom() {
    show();
  }

  public stopIntercom() {
    if (this.intercomChatIsLoaded) {
      this.intercomChatIsLoaded = false;
      shutdown();
    }
  }

  private clarityIdentify(user: AccountInfo) {
    if (window.clarity && user) {
      window.clarity('identify', user.userEmail);
    }
  }
}
